import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Animation from '~/src/components/Animation'
import { BlockContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const Header = ({ header }) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  if (header) {
    return (
      <Animation
        disabled
        animation="slideUp"
        className={classes.header}
      >
        {!!header && (
          <Typography
            html={header}
            component="div"
            className={classes.headerText}
          />
        )}
      </Animation>
    )
  }
  return null
}

Header.propTypes = {
  header: PropTypes.string,
}

Header.defaultProps = {
  header: null,
}

export default Header
