import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import React from 'react'

import Button from '../Button'
import styles from '../styles'

const useStyles = makeStyles(styles)

const Author = ({
  author,
  jobTitle,
  button,
  style,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.authorContainer} style={style}>
      <Typography variant="subtitle1">
        {author}
      </Typography>
      <Typography>
        {jobTitle}
      </Typography>
      <Button button={button} />
    </div>
  )
}

export default Author
