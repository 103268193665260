export default (theme) => {
  const { white } = theme.palette.common

  return {
    container: {
      justifyContent: 'center',
    },

    listBox: {
      backgroundColor: white,
      borderRadius: '10px',
      padding: '2rem 4rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        padding: '2rem',
      },
    },

    listItem: {
      display: 'flex',
      margin: '0.7rem 0',
      alignItems: 'center',
    },

    blockForeground: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        margin: '2rem',
      },
    },

    contentContainer: {
      margin: '4rem 6rem',
      [theme.breakpoints.down('md')]: {
        margin: '1rem',
        textAlign: 'center',
      },
    },

    flexOne: {
      flex: 1,
    },

    button: {
      marginTop: '2rem',
    },

    marginLeftOne: {
      marginLeft: '1rem',
    },

    contentTitle: {
      marginBottom: '1.5rem',
    },
  }
}
