import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import QuoteImage from '~/src/assets/quote.png'
import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import LinkButton from '~/src/components/LinkButton'
import RoundedImage from '~/src/components/RoundedImage'

import styles from './styles'

const useStyles = makeStyles(styles)

const ActionBlock = ({
  image,
  quote,
  content,
  button,
}) => {
  const classes = useStyles()

  return (
    <Block
      foregroundClassName={classes.foregroundContainer}
    >
      <Animation
        disabled
        animation="slideRight"
        className={classes.columnLeft}
      >
        <RoundedImage
          width="95%"
          image={image}
          className={classes.image}
        />
      </Animation>
      <Animation
        disabled
        animation="slideLeft"
        className={classes.columnRight}
      >
        <div className={classes.bubbleContainer}>
          <img
            loading="lazy"
            src={QuoteImage}
            alt="Say hi"
          />
          <div className={classes.bubbleText}>
            <Typography
              html={quote}
              variant="h3"
              component="h1"
              weight={700}
            />
          </div>
          <Typography
            component="div"
            html={content}
            className={classes.content}
          />
          <LinkButton
            to={button.url}
            target={button.target}
            data-identity={button.identity}
            event_name={button.event_name}
          >
            {button.text}
          </LinkButton>
        </div>
      </Animation>
    </Block>
  )
}

ActionBlock.propTypes = {
  image: PropTypes.object.isRequired,
  quote: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  button: PropTypes.object.isRequired,
}

export default ActionBlock
