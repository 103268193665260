import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'
import ImageLink from '~/src/components/ImageLink'
import LinkButton from '~/src/components/LinkButton'

import styles from './styles'

const useStyles = makeStyles(styles)

const ListBlock = ({
  title,
  subtitle,
  image,
  items,
  link,
  list_block_layout: propLayout,
  image_effect,
}) => {
  const layout = propLayout || 'fullWidth'
  const classes = useStyles({ layout })

  return (
    <Block
      foregroundClassName={classes.mainContainer}
    >
      <div className={classes.secondaryContainer}>
        <div className={classes.imageContainer}>
          <ImageLink
            {...image}
            imgStyles={cx(classes.image, {
              [classes.borderRadius]: image_effect,
            })}
          />
        </div>
        <div className={classes.textContainer}>
          <Typography
            variant="h4"
            component="h1"
            className={classes.textSpacing}
          >
            {title}
          </Typography>
          <ul className={classes.ul}>
            {items.map((item, index) => (
              <li
                className={cx(classes.li, classes.textSpacing)}
                key={index}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  weight={300}
                >
                  {item.content}
                </Typography>
              </li>
            ))}
          </ul>
          {!!subtitle && (
            <Typography
              variant="h5"
              component="h2"
            >
              {subtitle}
            </Typography>
          )}
          {link && (
            <LinkButton
              className={classes.button}
              to={link.url}
              target={link.target}
              data-identity={link.identity}
              event_name={link.event_name}
            >
              {link.text}
            </LinkButton>
          )}
        </div>
      </div>
    </Block>
  )
}

ListBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  list_block_layout: PropTypes.string.isRequired,
  link: PropTypes.object,
  image_effect: PropTypes.bool.isRequired,
}

ListBlock.defaultProps = {
  subtitle: '',
  link: null,
}

export default ListBlock
