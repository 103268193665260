import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import { BROWSER } from '~/config'
import Blocks from '~/src/blocks'
import Page from '~/src/components/Page'
import { BlockContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const BasicPage = ({ pageContext, layoutContext }) => {
  const { blocks, settingContext } = pageContext

  const classes = useStyles()

  if (settingContext?.redirect && BROWSER) {
    window.location = settingContext.redirect
  }

  return !settingContext?.redirect && (
    <Page layoutContext={layoutContext} settingContext={settingContext}>
      <div className={classes.stroke} />
      {blocks.map((block, index) => {
        const {
          typeName,
          data,
          backgroundColor,
          gradient,
          transparent,
          identity,
        } = block

        const blockContext = {
          backgroundColor,
          gradient,
          transparent,
          identity,
          typeName,
        }

        const PageBlock = Blocks[typeName]
        if (!PageBlock) return null

        return (
          <BlockContext.Provider
            key={`${index}_${block.data.__typename}`}
            value={blockContext}
          >
            <PageBlock {...data} />
          </BlockContext.Provider>
        )
      })}
    </Page>
  )
}

BasicPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default BasicPage
