import { Box, Chip, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import Link from '~/src/components/Link'
import LinkButton from '~/src/components/LinkButton'
import { BlockContext, PostContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const Post = ({ post, index }) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({
    backgroundColor,
    coverImage: post.cover_image?.src?.data?.attributes?.url,
  })

  return (
    <Animation
      animation="pop"
      delay={`${200 * index}`}
      className={classes.post}
    >
      <Box boxShadow={2} className={classes.box}>
        <Link to={`/blog/${post.meta.slug}`} className={classes.link}>
          <div className={classes.imageContainer} />
          <div className={classes.postContent}>
            <div className={classes.postInfo}>
              <div>
                <Typography
                  variant="body2"
                  color="lightGrey"
                  className={classes.time}
                >
                  {moment.utc(post.created_time).format('lll')}
                </Typography>
                <Typography
                  weight={700}
                  color="black"
                >
                  {post.title}
                </Typography>
              </div>
            </div>
            <div>
              {post.tags && (
                <div className={classes.postTagsContainer}>
                  {post.tags.map((tag) => (
                    <Chip
                      key={tag}
                      size="small"
                      label={tag}
                      className={classes.tag}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Link>
      </Box>
    </Animation>
  )
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

const LatestPostsBlock = ({
  title,
  amount,
  read_more_text,
  categories,
}) => {
  const { posts: allPosts } = useContext(PostContext)
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  const [posts, setPosts] = useState(allPosts)

  useEffect(() => {
    // Filter posts by categories (tags)
    if (categories && categories !== '' && allPosts.length) {
      const categoriesArr = categories.split(',')
        .map((category) => category.trim().toLowerCase())

      const newPosts = allPosts.filter((post) => {
        if (!post.tags) return false
        return post.tags.some((tag) => categoriesArr.includes(tag.toLowerCase()))
      })

      setPosts(newPosts)
    }
  }, [])

  return (
    <Block
      containerClassName={classes.mainContainer}
    >
      <Animation
        disabled
        animation="slideUp"
      >
        <Typography
          variant="h4"
          component="h1"
          className={classes.h4}
        >
          {title}
        </Typography>
      </Animation>
      <div className={classes.posts}>
        {posts.slice(0, amount).map((item, index) => (
          <Post
            key={item.id}
            index={index}
            post={item}
          />
        ))}
      </div>
      {!!read_more_text && (
        <LinkButton
          className={classes.button}
          to="/blog"
        >
          {read_more_text}
        </LinkButton>
      )}
    </Block>
  )
}

LatestPostsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  read_more_text: PropTypes.string,
  categories: PropTypes.string,
}

LatestPostsBlock.defaultProps = {
  read_more_text: '',
  categories: '',
}

export default LatestPostsBlock
