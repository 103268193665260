import { Box, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Animation from '~/src/components/Animation'
import ImageLink from '~/src/components/ImageLink'
import { BlockContext } from '~/src/contexts'

import Links from '../Links'
import styles from './styles'

const useStyles = makeStyles(styles)

const Body = ({
  title,
  description,
  align_text,
  image,
  two_columns_layout: layout,
  icon_links,
  image_shadow,
  image_border_radius,
}) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({
    backgroundColor,
    layout,
    textAlign: align_text,
  })

  return (
    <div className={classes.columns}>
      <Animation
        disabled
        animation={layout === 'flipped' ? 'slideLeft' : 'slideRight'}
        className={classes.column}
      >
        <Box
          boxShadow={image_shadow ? 3 : 0}
          className={classes.imageContainer}
        >
          <ImageLink
            {...image}
            imgStyles={cx(classes.image, {
              [classes.borderRadius]: image_border_radius,
            })}
          />
        </Box>
      </Animation>
      <div className={cx(classes.column, classes.textContainer)}>
        <Animation
          disabled
          animation={layout === 'flipped' ? 'slideRight' : 'slideLeft'}
        >
          {!!title && (
            <Typography
              html={title}
              component="div"
              className={classes.row}
            />
          )}
          <Typography
            component="div"
            className={classes.row}
            html={description}
          />
        </Animation>
        {/* dont need to animate action button to prevent stuck */}
        {icon_links.length > 0 && <Links links={icon_links} />}
      </div>
    </div>
  )
}

Body.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  align_text: PropTypes.array,
  image: PropTypes.object.isRequired,
  image_shadow: PropTypes.bool,
  image_border_radius: PropTypes.bool,
  two_columns_layout: PropTypes.string,
  icon_links: PropTypes.array,
}

Body.defaultProps = {
  align_text: 'center',
  image_shadow: false,
  image_border_radius: false,
  two_columns_layout: 'default',
  icon_links: [],
}

export default Body
