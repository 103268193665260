import { Box, Typography, Underline } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Icon from '~/src/components/Icon'

import styles from './styles'

const useStyles = makeStyles(styles)

const FloatingItem = ({
  item,
  index,
  onChange,
  selectedIndex,
  filled,
  activeClassName,
  className,
}) => {
  const classes = useStyles({ filled })

  return (
    <div
      className={cx(classes.thumbnailItem, className, {
        [classes.activeThumbnail]: selectedIndex === index,
        [activeClassName]: selectedIndex === index,
      })}
      key={index}
      onClick={() => onChange(index)}
      data-identity={item?.link?.identity}
    >
      <Icon
        className={!item?.icon?.image ? '' : classes.thumbnailIcon}
        {...item?.icon}
      />
      <Typography className={classes.thumbnailLabel}>
        {item?.link?.text}
      </Typography>
      {(selectedIndex === index && !filled) && (
        <Underline className={classes.underline} />
      )}
    </div>
  )
}

FloatingItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

const FloatingBar = ({
  items,
  containerClassName,
  itemActiveClassName,
  itemClassName,
  selectedIndex,
  setSelectedIndex,
  float,
  boxShadow,
  filled,
}) => {
  const classes = useStyles({ float })

  return (
    <Box
      className={cx(classes.floatingBar, containerClassName)}
      boxShadow={boxShadow}
    >
      {items.map((element, i) => (
        <FloatingItem
          item={element}
          index={i}
          key={i}
          onChange={(index) => setSelectedIndex(index)}
          selectedIndex={selectedIndex}
          filled={filled}
          activeClassName={itemActiveClassName}
          className={itemClassName}
        />
      ))}
    </Box>
  )
}

FloatingBar.propTypes = {
  items: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  itemActiveClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  float: PropTypes.bool,
  boxShadow: PropTypes.number,
  filled: PropTypes.bool,
}

FloatingBar.defaultProps = {
  containerClassName: '',
  itemActiveClassName: '',
  itemClassName: '',
  float: false,
  boxShadow: 5,
  filled: false,
}

export default FloatingBar
