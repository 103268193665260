import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'

import styles from './styles'

const useStyles = makeStyles(styles)

const CustomAnimation = ({ animation, children }) => {
  if (animation) {
    return (
      <Animation
        disabled
        animation="slideUp"
        offset="100"
      >
        {children}
      </Animation>
    )
  }

  return children
}

CustomAnimation.propTypes = {
  animation: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

const Paragraph = (props) => {
  const classes = useStyles()
  const { header, subheader, content } = props.data
  const isNotEmpty = (string) => { return string !== '' }

  return (
    <div className={classes.contentContainer}>
      {isNotEmpty(header) && (
        <CustomAnimation
          animation={props.animation}
        >
          <Typography
            variant="h5"
            component="h2"
            className={classes.textContainer}
          >
            {header}
          </Typography>
        </CustomAnimation>
      )}
      {isNotEmpty(subheader) && (
        <CustomAnimation
          animation={props.animation}
        >
          <Typography
            variant="h6"
            component="h3"
            className={classes.textContainer}
          >
            {subheader}
          </Typography>
        </CustomAnimation>
      )}
      {isNotEmpty(content) && (
        <CustomAnimation
          animation={props.animation}
        >
          <Typography
            variant="subtitle1"
            className={cx(classes.textContainer, classes.bodyText)}
            component="div"
            html={content}
          />
        </CustomAnimation>
      )}
    </div>
  )
}

Paragraph.propTypes = {
  data: PropTypes.object.isRequired,
  animation: PropTypes.bool.isRequired,
}

const ParagraphsBlock = ({
  title,
  animation,
  paragraphs,
}) => {
  const classes = useStyles()

  return (
    <Block
      fullWidth
    >
      <div className={classes.mainContainer}>
        <CustomAnimation
          animation={animation}
        >
          <Typography
            variant="h3"
            component="h1"
            className={classes.headerText}
          >
            {title}
          </Typography>
        </CustomAnimation>
        {paragraphs.map((paragraph, index) => (
          <Paragraph
            key={index}
            data={paragraph}
            animation={animation}
          />
        ))}
      </div>
    </Block>
  )
}

ParagraphsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  animation: PropTypes.bool,
  paragraphs: PropTypes.array.isRequired,
}

ParagraphsBlock.defaultProps = {
  animation: true,
}

export default ParagraphsBlock
