export default (theme) => {
  const { white, paleGrey } = theme.palette.common
  const { maxContentWidth } = theme.global

  const heroMiddlePadding = '1.5rem'

  return {
    mainContainer: {
      overflow: 'visible',
    },

    foregroundContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },

    hero: {
      maxWidth: maxContentWidth,
      margin: '0 auto 5rem auto',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        paddingTop: heroMiddlePadding,
        margin: '0 auto',
      },
    },

    heroLeft: {
      flex: 1,
      paddingRight: heroMiddlePadding,
      margin: '1rem',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& img': {
          maxWidth: '40vh',
        },
      },
    },

    heroRight: {
      flex: 1,
      color: white,
      paddingLeft: heroMiddlePadding,
      [theme.breakpoints.down('sm')]: {
        flex: 0,
        maxWidth: '90vw',
        textAlign: 'center',
        paddingLeft: 0,
      },
    },

    floatingBar: {
      minHeight: '7rem',
      display: 'flex',
      borderRadius: '10px',
      backgroundColor: theme.palette.common.white,
      width: maxContentWidth,
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: '-4rem',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '3rem',
        position: 'static',
        bottom: 'auto',
        width: '100vw',
        borderRadius: 0,
        overflow: 'scroll',
        padding: '0 1rem',
        boxShadow: 'none',
      },
    },

    thumbnailItem: {
      textAlign: 'center',
      padding: '2rem',
      flex: 1,
      cursor: 'pointer',
      borderRight: `1px ${paleGrey} solid`,
      position: 'relative',
      '&:last-child': {
        borderRight: 'none',
      },
      color: 'inherit',
      '& img': {
        height: '24px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '1rem',
        '& img': {
          height: '20px',
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        borderRight: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          height: '16px',
        },
      },
    },

    activeThumbnail: {
      color: theme.palette.common.primary,
    },

    thumbnailLabel: {
      marginTop: '1rem',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    underline: {
      position: 'absolute',
      bottom: 0,
    },

    title: {
      position: 'relative',
      marginBottom: '2rem',
      [theme.breakpoints.down('sm')]: {
        '& h3': {
          fontSize: '1.5rem',
        },
      },
    },

    titleUnderline: {
      position: 'absolute',
      bottom: '-0.75rem',
    },

    description: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.95rem',
        lineHeight: '1.2rem',
      },
    },

    buttons: {
      marginTop: '1rem',
    },

    button: {
      marginRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },

    heroImage: {
      height: '100%',
    },
  }
}
