import {
  Button,
  Collapse,
  Icon as MuiIcon,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import Icon from '~/src/components/Icon'
import Link from '~/src/components/Link'
import LinkButton from '~/src/components/LinkButton'
import { BlockContext } from '~/src/contexts'
import { useBrowserSize, useTranslation } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const Item = ({
  index,
  label,
  description,
  icon,
  link,
}) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  return (
    <Animation
      disabled
      animation="slideUp"
      delay={`${100 * index}`}
      className={classes.item}
    >
      <div className={classes.iconContainer}>
        <Icon
          className={classes.image}
          color="secondary"
          {...icon}
        />
      </div>
      <div
        className={cx(classes.inlineLabel, {
          [classes.noDescriptionAndLink]: !description && !link,
        })}
      >
        <Typography
          variant="h6"
          component="h2"
          className={classes.label}
        >
          {label}
        </Typography>
        {!!description && (
          <Typography
            className={classes.description}
          >
            {description}
          </Typography>
        )}
        {link && (
          <div className={classes.itemLinkContainer}>
            <Link
              className={classes.itemLink}
              to={link.url}
              target={link.target}
              data-identity={link.identity}
              event_name={link.event_name}
            >
              {link.text}
            </Link>
          </div>
        )}
      </div>
    </Animation>
  )
}

Item.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.object,
}

Item.defaultProps = {
  link: null,
  description: '',
  icon: null,
}

const ItemsBlock = ({
  title,
  // iconShadow, // TODO Add shadow to icon
  items,
  button,
}) => {
  const screenSize = useBrowserSize()
  const t = useTranslation()
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  const defaultItems = {
    mobile: 4,
    desktop: 6,
  }
  const view = ['xs', 'sm', 'md'].includes(screenSize.toLowerCase()) ? 'mobile' : 'desktop'
  const shouldShowExpandButton = items.length > defaultItems[view]

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    // Reset expanded when view changes
    setExpanded(false)
  }, [view])

  const handleExpanded = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <Block containerClassName={classes.mainContainer}>
      <div className={classes.secondaryContainer}>
        <Animation
          disabled
          animation="slideUp"
        >
          <Typography
            html={title}
            component="div"
            className={classes.title}
          />
        </Animation>
        <div
          className={classes.itemsBlock}
        >
          {items.slice(0, defaultItems[view]).map((item, index) => (
            <Item
              key={item.label}
              index={index}
              {...item}
            />
          ))}
        </div>
        {shouldShowExpandButton && (
          <Collapse
            in={expanded}
            timeout={500}
          >
            <div
              className={classes.itemsBlock}
            >
              {items.slice(defaultItems[view], items.length).map((item, index) => (
                <Item
                  key={item.label}
                  index={index}
                  {...item}
                />
              ))}
            </div>
          </Collapse>
        )}
        <div className={classes.buttonsContainer}>
          {!!button && (
            <LinkButton
              buttonClassName={classes.generalButton}
              to={button.url}
              target={button.target}
              data-identity={button.identity}
              event_name={button.event_name}
            >
              {button.text}
            </LinkButton>
          )}
          {shouldShowExpandButton && (
            <Button
              variant="text"
              className={cx(classes.expandButton)}
              onClick={handleExpanded}
              disableRipple
            >
              <Typography className={classes.expandButtonText}>
                {expanded ? t('showLess') : t('showMore')}
              </Typography>
              <MuiIcon
                name={expanded ? 'expand_less' : 'expand_more'}
                size="24px"
              />
            </Button>
          )}
        </div>
      </div>
    </Block>
  )
}

ItemsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  // iconShadow: PropTypes.bool,
  items: PropTypes.array.isRequired,
  button: PropTypes.object,
}

ItemsBlock.defaultProps = {
  // iconShadow: false,
  button: null,
}

export default ItemsBlock
