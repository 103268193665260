export default (theme) => {
  const { white } = theme.palette.common
  const cardWidth = 293
  return {
    mainContainer: {
      padding: theme.spacing(2, 0),
      display: 'flex',
      textAlign: 'center',
    },

    horizontalBlock: {
      margin: theme.spacing(8, 0),
    },

    verticalBlock: {
      padding: theme.spacing(0, 3),
    },

    cardsContainer: {
      marginTop: theme.spacing(6),
    },

    cardsHGrid: ({ itemsLength, windowWidth }) => ({
      paddingInlineStart: theme.spacing(8),
      paddingInlineEnd: theme.spacing(8),
      overflowX: 'auto',
      overflowY: 'hidden',
      paddingBottom: theme.spacing(3),
      justifyContent: itemsLength * cardWidth + 40 < windowWidth ? 'center' : 'flex-start',
      [theme.breakpoints.between('xs', 'sm')]: {
        justifyContent: 'center',
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
      },
      [theme.breakpoints.up('md')]: {
        flexWrap: 'nowrap',
      },
      '&::-webkit-scrollbar': {
        borderRadius: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
        borderRadius: 8,
      },
    }),

    cardsVGrid: {
      [theme.breakpoints.between('xs', 'sm')]: {
        justifyContent: 'space-evenly',
      },
    },

    boxHorizontal: {
      margin: theme.spacing(0, 1),
      flexShrink: 0,
      background: white,
      borderRadius: 10,
      height: '100%',
    },

    boxVertical: {
      height: '100%',
      background: white,
      borderRadius: 10,
    },

    cardHorizontal: {
      maxWidth: cardWidth,
      minWidth: cardWidth,
      width: cardWidth,
      [theme.breakpoints.between('xs', 'sm')]: {
        maxWidth: cardWidth * 1.1,
        minWidth: cardWidth * 1.1,
        width: cardWidth * 1.1,
        padding: theme.spacing(0, 3, 3, 3),
      },
    },

    cardVertical: {
      [theme.breakpoints.between('xs', 'sm')]: {
        maxWidth: cardWidth * 1.1,
        minWidth: cardWidth * 1.1,
        width: cardWidth * 1.1,
        padding: theme.spacing(0, 3, 3, 3),
      },
    },

    cardContainer: {
      height: '100%',
    },

    cardImage: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      '& picture, & img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    imageExtended: {
      height: 230,
      marginBottom: theme.spacing(3),
    },

    cardContent: {
      flex: 1,
      padding: theme.spacing(0, 3, 4, 3),
      textAlign: 'left',
      justifyContent: 'space-between',
    },

    itemContent: {
      margin: theme.spacing(1, 0, 4, 0),
    },

    contentHorizontal: {
      marginBottom: 0,
    },

    image: {
      width: '100%',
      height: 'auto',
    },

    buttonLinkHorizontal: {
      marginTop: theme.spacing(2),
    },

    buttonLinkVertical: {
      marginTop: 'auto',
    },

    button: {
      textAlign: 'center',
      width: '100%',
    },

    buttonVertical: {
      padding: theme.spacing(2),
    },
  }
}
