export default (theme) => {
  const { white, paleGrey, primary } = theme.palette.common

  return {
    floatingBar: ({ float }) => ({
      minHeight: '7rem',
      display: 'flex',
      borderRadius: '14px',
      backgroundColor: white,
      justifyContent: 'space-between',
      position: float ? 'absolute' : 'relative',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '3rem',
        position: 'static',
        bottom: 'auto',
        width: '100vw',
        borderRadius: 0,
        overflow: 'scroll',
        padding: '0 1rem',
      },
    }),

    thumbnailItem: {
      textAlign: 'center',
      padding: '2rem',
      flex: 1,
      cursor: 'pointer',
      borderRight: `1px ${paleGrey} solid`,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '&:last-child': {
        borderRight: 'none',
      },
      color: 'inherit',
      '& img': {
        height: '24px',
      },
      [theme.breakpoints.down('md')]: {
        padding: '1rem',
        '& img': {
          height: '20px',
        },
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        borderRight: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          height: '16px',
        },
      },
    },

    activeThumbnail: ({ filled }) => {
      const style = {
        borderRadius: '14px',
        backgroundColor: white,
        color: primary,
        '&:after': {
          content: '""',
          position: 'absolute',
          borderLeft: '15px solid rgba(0, 0, 0, 0)',
          borderRight: '15px solid rgba(0, 0, 0, 0)',
          borderTop: `15px solid ${primary}`,
          display: 'inline-block',
          bottom: '-0.9rem',
          left: '44%',
        },
      }

      if (filled) {
        style.backgroundColor = primary
        style.color = white
      }

      return style
    },

    thumbnailLabel: {
      fontWeight: 'bold',
    },

    underline: {
      position: 'absolute',
      bottom: 0,
    },

    thumbnailIcon: {
      width: '64px',
      height: 'auto !important',
    },
  }
}
