import { Box, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import Link from '~/src/components/Link'
import { useIsMobile } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const Card = ({
  index,
  title,
  content,
  link,
}) => {
  const classes = useStyles()
  return (
    <Animation
      animation="pop"
      delay={`${200 * index}`}
      className={classes.card}
    >
      <Box
        boxShadow={2}
        className={classes.box}
      >
        <div>
          <Typography
            html={title}
            component="div"
          />
        </div>
        <Typography
          component="div"
          className={classes.content}
          html={content}
        />
        {link && (
          <Link
            to={link.url}
            target={link.target}
            data-identity={link.identity}
            event_name={link.event_name}
          >
            <Typography
              color="primary"
              weight={700}
            >
              {link.text}
            </Typography>
          </Link>
        )}
      </Box>
    </Animation>
  )
}

Card.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.object,
}

Card.defaultProps = {
  link: null,
}

const Carousel = ({ children }) => {
  const isMobile = useIsMobile()
  const classes = useStyles()

  if (isMobile) {
    return (
      <ReactCarousel
        centerMode
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        selectedItem={1}
        className={classes.cardsBlock}
        centerSlidePercentage={70}
      >
        {children}
      </ReactCarousel>
    )
  }

  return (
    <div className={classes.cardsBlock}>
      {children}
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
}

const CardsBlock = ({
  header_content,
  cards,
}) => {
  const classes = useStyles()

  return (
    <Block
      containerClassName={classes.mainContainer}
    >
      <Animation
        disabled
        animation="pop"
      >
        <Typography
          component="div"
          className={classes.header}
          html={header_content}
        />
      </Animation>
      <Carousel>
        {cards.map((item, index) => (
          <Card
            key={index}
            index={index}
            {...item}
          />
        ))}
      </Carousel>
    </Block>
  )
}

CardsBlock.propTypes = {
  header_content: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
}

export default CardsBlock
