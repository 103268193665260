import { Grid, Typography } from '@pickupp/ui/core'
import { makeStyles, useTheme } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import LinkButton from '~/src/components/LinkButton'
import { BlockContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const SimpleTextBlock = ({
  title,
  description,
  icon_link,
}) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles()
  const theme = useTheme()
  const textColor = theme.utils.isLightColor(backgroundColor) ? 'black' : 'white'
  const color = theme.utils.isLightColor(backgroundColor) ? 'primary' : 'white'

  return (
    <Block
      foregroundClassName={classes.mainContainer}
    >
      <Animation
        disabled
        animation="slideUp"
        offset="100"
      >
        <Typography
          color={textColor}
          className={classes.textSpacing}
          html={title}
        />
      </Animation>
      <Animation
        disabled
        animation="slideUp"
        offset="100"
      >
        <Typography
          className={classes.description}
          color={textColor}
          html={description}
        />
      </Animation>
      <Grid className={classes.buttons}>
        {icon_link.map((item, index) => item.link && (
          <Animation
            disabled
            animation="slideUp"
            offset="100"
            key={index}
          >
            <LinkButton
              to={item.link?.url}
              target={item.link?.target}
              icon={{ icon: item.icon }}
              data-identity={item.link?.identity}
              className={classes.button}
              buttonClassName={classes.innerButton}
              color={color === 'primary' && index === 0 ? color : 'white'}
              event_name={item.link?.event_name}
            >
              {item.link?.text}
            </LinkButton>
          </Animation>
        ))}
      </Grid>
    </Block>
  )
}

SimpleTextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon_link: PropTypes.array,
}

SimpleTextBlock.defaultProps = {
  description: '',
  icon_link: [],
}

export default SimpleTextBlock
