import { Cell, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'
import Form from '~/src/components/Form'
import { useIsMobile } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const RegistrationBlock = ({
  title,
  form,
  image,
}) => {
  const classes = useStyles({ image })
  const isMobile = useIsMobile()
  const shouldShowImage = !isMobile && image?.src

  return (
    <Block
      fullWidth
    >
      <div className={classes.mainContainer}>
        <div className={classes.primaryContent}>
          <Cell
            md={shouldShowImage ? 6 : 12}
            className={classes.leftSection}
          >
            <div className={classes.formContainer}>
              {title && (
                <Typography
                  variant="h2"
                  className={classes.textSpacing}
                >
                  {title}
                </Typography>
              )}
              {form && <Form data={form} />}
            </div>
          </Cell>
          {shouldShowImage && (
            <Cell
              md={6}
              className={classes.rightSection}
            >
              <div className={classes.imageContainer}>
                <img
                  src={image?.src?.data?.attributes?.url}
                  alt={image?.alt || ''}
                />
              </div>
            </Cell>
          )}
        </div>
      </div>
    </Block>
  )
}

RegistrationBlock.propTypes = {
  title: PropTypes.string,
  form: PropTypes.object.isRequired,
  image: PropTypes.object,
}

RegistrationBlock.defaultProps = {
  title: null,
  image: null,
}

export default RegistrationBlock
