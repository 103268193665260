import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'underscore'

import { BROWSER } from '~/config'
import Block from '~/src/components/Block'
import { getTargetUrlWithPageUtmParams, randomString } from '~/src/services/utils'

import styles from './styles'

const useStyles = makeStyles(styles)

const getIframeSrc = (link, ctaLink, id) => {
  if (!link || !link?.url || typeof link.url !== 'string') return null

  const linkUrl = getTargetUrlWithPageUtmParams(link.url.trim())
  const linkHasQuery = /[?|&]/g.test(linkUrl)
  const hasEventName = !!link?.event_name
  const denominator = linkHasQuery ? '&' : '?'

  const queryParams = _.compact([
    id && `id=${id}`,
    hasEventName && `pixel_event=${link.event_name}`,
    hasEventName && ctaLink?.url && `ctaLink=${ctaLink.url}`,
    hasEventName && ctaLink?.text && `ctaText=${ctaLink.text?.replaceAll(' ', '')}`,
    hasEventName && ctaLink?.target && `ctaTarget=${ctaLink.target}`,
  ]).join('&')

  return `${linkUrl}${denominator}${queryParams}`
}

const IframeBlock = ({
  link,
  title,
  header,
  coverage,
  auto_height,
  enable_click,
  cta_link,
}) => {
  const classes = useStyles()
  const wrapperRef = useRef()

  const [contentSize, setContentSize] = useState({})
  const [id] = useState(randomString(8))

  const iframeSrc = getIframeSrc(link, cta_link, id)

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (!event.data.id || event.data.id !== id) return

      if (event.data.contentSize) {
        setContentSize(event.data.contentSize)
      }
      if (event.data.redirect) {
        window.open(event.data.redirect, '_self')
      }
    })
  }, [])

  useEffect(() => {
    if (contentSize.height && BROWSER) {
      const iframe = wrapperRef.current?.getElementsByTagName('iframe')
      if (
        iframe?.length > 0 &&
        iframe[0].src.includes('pickupp.io') // only apply for pickupp iframe, apply for pages that have multiple media block
      ) {
        iframe[0].style.height = auto_height ? `${contentSize.height}px` : null
        iframe[0].style.marginTop = auto_height ? 0 : null
      }
    }
  }, [contentSize, BROWSER])

  return (
    <Block fullWidth>
      {!!header && (
        <Typography
          color="primary"
          variant="h4"
          className={classes.header}
          component="h1"
        >
          {header}
        </Typography>
      )}
      <div ref={wrapperRef}>
        <iframe
          title="coverage"
          src={iframeSrc}
          className={classes.iframe}
          allowFullScreen
          loading="lazy"
          style={{
            pointerEvents: !enable_click ? 'none' : null,
          }}
        />
      </div>
      {(!!title || coverage.length > 0) && (
        <div className={classes.contentContainer}>
          {!!title && (
            <div className={classes.primaryContainer}>
              <Typography
                variant="h4"
                html={title}
              />
            </div>
          )}
          {coverage?.length > 0 && (
            <ul className={classes.secondaryContainer}>
              {coverage.map((list, key) => (
                <li className={classes.textSpacing} key={key}>
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    weight={300}
                  >
                    {list.content}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Block>
  )
}

IframeBlock.propTypes = {
  header: PropTypes.string,
  link: PropTypes.object.isRequired,
  title: PropTypes.string,
  coverage: PropTypes.array.isRequired,
  auto_height: PropTypes.bool,
  enable_click: PropTypes.bool,
  cta_link: PropTypes.object,
}

IframeBlock.defaultProps = {
  header: '',
  title: '',
  auto_height: false,
  enable_click: false,
  cta_link: null,
}

export default IframeBlock
