import { Icon, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Block from '~/src/components/Block'
import Image from '~/src/components/Image'
import Paragraph from '~/src/components/Paragraph'

import styles from './styles'

const useStyles = makeStyles(styles)

const Item = ({ item, index }) => {
  const classes = useStyles()
  const [active, setActive] = useState(index === 0)

  const contentMap = {
    paragraph: (data) => <Paragraph {...data} />,
    image: (data) => (
      <Image
        {...data}
        className={classes.image}
      />
    ),
  }

  return (
    <>
      <div
        className={cx({
          [classes.question]: true,
          [classes.primaryColor]: active,
        })}
        onClick={() => setActive(!active)}
      >
        <Typography weight={600}>
          {item.title}
        </Typography>
        <Icon
          name={active ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        />
      </div>
      <div
        className={cx({
          [classes.answer]: true,
          [classes.activeAnswer]: active,
        })}
      >
        {item.items?.map((data, i) => {
          return (
            <React.Fragment key={i}>
              {Object.keys(contentMap).map((key) => data[key] && contentMap[key](data[key]))}
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

const ExpandV2Block = ({
  header,
  subheader,
  items,
}) => {
  const classes = useStyles()

  return (
    <Block
      fullWidth
      foregroundClassName={classes.foregroundContainer}
      containerClassName={cx(classes.mainContainer, classes.body)}
    >
      <Typography
        className={classes.header}
        variant="h3"
        color="primary"
      >
        {header}
      </Typography>
      <Typography
        className={classes.title}
        variant="h5"
      >
        {subheader}
      </Typography>
      {items.map((item, index) => (
        <Item
          key={index}
          index={index}
          item={item}
        />
      ))}
    </Block>
  )
}

ExpandV2Block.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export default ExpandV2Block
