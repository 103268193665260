export default (theme) => {
  const { maxContentWidth } = theme.global
  const { white, darkGrey } = theme.palette.common
  const { isLightColor } = theme.utils

  return {

    title: {
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '2rem',
      },
    },

    subtitle: {
      marginTop: '1rem',
      textAlign: 'center',
    },

    description: {
      maxWidth: '800px',
      margin: 'auto',
      padding: '2rem',
      textAlign: 'center',
    },

    blocksContainer: ({ backgroundColor }) => ({
      textAlign: 'center',
      maxWidth: maxContentWidth,
      margin: '5rem auto',
      justifyContent: 'center',
      color: isLightColor(backgroundColor) ? darkGrey : white,
      [theme.breakpoints.down('md')]: {
        margin: '3rem auto',
      },
    }),

    blocks: {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5),
        backgroundColor: white,
      },
    },

    block: {
      margin: '1rem',
      backgroundColor: white,
      color: darkGrey,
      borderRadius: '10px',
      minHeight: '13rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': {
        fontWeight: 'bold',
      },
      '& b': {
        fontSize: '3rem',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0',
        minHeight: '11rem',
        borderRadius: 0,
        flexGrow: 0,
        '& b': {
          fontSize: '2.5rem',
        },
        '& p': {
          fontSize: '0.9rem',
        },
      },
    },

    itemContainer: {
      [theme.breakpoints.down('xs')]: {
        '&:nth-child(even)': {
          borderLeft: '0.5px solid #EAEAEA',
        },
        '&:nth-child(n+3)': {
          borderTop: '0.5px solid #EAEAEA',
        },
      },
    },
  }
}
