import {
  Cell,
  Grid,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import React, { useEffect, useState } from 'react'
import { SwitchTransition, Transition } from 'react-transition-group'

import Block from '~/src/components/Block'
import Image from '~/src/components/Image'
import { useIsMobile } from '~/src/hooks'
import { makeTransitions } from '~/src/services/utils'

import Author from './Author'
import Content from './Content'
import styles from './styles'

const useStyles = makeStyles(styles)

const Title = ({ title }) => {
  const classes = useStyles()
  return (
    <div className={classes.titleContainer}>
      <Typography variant="h2" color="primary">
        {title}
      </Typography>
    </div>
  )
}

const AnimatedImage = ({
  style,
  shouldAnimate,
  item,
}) => {
  const classes = useStyles()

  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={item.author}
        in={shouldAnimate}
        timeout={200}
      >
        {(state) => {
          return (
            <Image
              src={item.image}
              alt={item.author}
              className={classes.authorImageContainer}
              style={{
                ...style.defaultStyle,
                ...style[state],
              }}
            />
          )
        }}
      </Transition>
    </SwitchTransition>
  )
}

const AnimatedContent = ({
  shouldAnimate,
  selectedIndex,
  onChangeIndex,
  item,
  style,
}) => {
  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={item.author}
        in={shouldAnimate}
        timeout={200}
      >
        {(state) => (
          <>
            <Content
              content={item.content}
              index={selectedIndex}
              setSelectedIndex={onChangeIndex}
              style={{
                ...style.defaultStyle,
                ...style[state],
              }}
            />
            <Author
              author={item.author}
              jobTitle={item.job_title}
              button={item.button}
              style={{
                ...style.defaultStyle,
                ...style[state],
              }}
            />
          </>
        )}
      </Transition>
    </SwitchTransition>
  )
}

const SliderBlock = ({ items, title, images }) => {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [item, setItem] = useState(items[0])
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [slideAnimationStyles, setSlideAnimationStyles] = useState({})
  const imageAnimationStyles = makeTransitions({
    duration: isMobile ? 200 : 150,
    type: 'fadeIn',
  })

  const SLIDE_DIRECTION_MAP = {
    left: 'slideLeftFadeOut',
    right: 'slideRightFadeOut',
  }
  const animationDelay = isMobile ? 300 : 200

  useEffect(() => {
    setItem(items[selectedIndex])
  }, [selectedIndex])

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => {
        setShouldAnimate(false)
      }, animationDelay)
    }
  }, [shouldAnimate])

  const onChangeIndex = ({ value, direction }) => {
    if (value > items.length - 1) {
      setSelectedIndex(0)
    } else if (value < 0) {
      setSelectedIndex(items.length - 1)
    } else {
      setSelectedIndex(value)
    }
    setShouldAnimate(true)
    const slidePropertyName = SLIDE_DIRECTION_MAP[direction]
    setSlideAnimationStyles(makeTransitions({
      duration: isMobile ? 600 : 500,
      type: slidePropertyName,
    }))
  }

  return (
    <Block>
      <Grid
        className={classes.contentContainer}
        justify={!isMobile ? 'space-between' : null}
      >
        {isMobile ? (
          <>
            <Cell xs={12}>
              <Title title={title} />
            </Cell>
            <Cell xs={12}>
              <AnimatedImage
                shouldAnimate={shouldAnimate}
                style={imageAnimationStyles}
                item={item}
              />
            </Cell>

            <Cell xs={12}>
              <AnimatedContent
                shouldAnimate={shouldAnimate}
                selectedIndex={selectedIndex}
                onChangeIndex={onChangeIndex}
                style={slideAnimationStyles}
                item={item}
              />
            </Cell>
          </>
        ) : (
          <>
            <Cell md={6}>
              <Title title={title} />
              <AnimatedContent
                shouldAnimate={shouldAnimate}
                selectedIndex={selectedIndex}
                onChangeIndex={onChangeIndex}
                style={slideAnimationStyles}
                item={item}
              />
            </Cell>

            <Cell md={2} />

            <Cell md={4}>
              <AnimatedImage
                shouldAnimate={shouldAnimate}
                style={imageAnimationStyles}
                item={item}
              />
            </Cell>
          </>
        )}
      </Grid>
      {images && (
        <Grid
          justify="center"
          alignItems="center"
          className={classes.imagesContainer}
        >
          {images.map((image, index) => {
            const alt = `sliderImage-${index + 1}`
            const imageLink = {
              url: image.url,
              target: 'new_tab',
            }
            return (
              <Image
                key={alt}
                link={imageLink}
                src={image.image}
                alt={alt}
                className={classes.imageContainer}
              />
            )
          })}
        </Grid>
      )}
    </Block>
  )
}

export default SliderBlock
