import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import Link from '~/src/components/Link'
import { useParentSize } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const ImageLink = ({
  src,
  alt,
  link,
  imgStyles,
}) => {
  const classes = useStyles()
  const imgRef = useRef(null)
  const { width: pWidth, height: pHeight } = useParentSize({ ref: imgRef })

  if (link) {
    return (
      <Link
        to={link.url}
        target={link.target}
        data-identity={link.identity}
        className={classes.link}
        event_name={link.event_name}
      >
        <img
          ref={imgRef}
          className={imgStyles}
          loading="lazy"
          src={src.data?.attributes?.url}
          alt={alt}
          width={pWidth}
          height={pHeight}
        />
      </Link>
    )
  }

  return (
    <img
      ref={imgRef}
      className={imgStyles}
      loading="lazy"
      src={src.data?.attributes?.url}
      alt={alt}
      width={pWidth}
      height={pHeight}
    />
  )
}

ImageLink.propTypes = {
  src: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  link: PropTypes.object,
  imgStyles: PropTypes.string,
}

ImageLink.defaultProps = {
  imgStyles: '',
  link: null,
}

export default ImageLink
