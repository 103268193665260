import {
  Box,
  Cell,
  Grid,
  Icon,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'
import LinkButton from '~/src/components/LinkButton'
import { useIsMobile } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const Button = ({ button }) => {
  const classes = useStyles()

  if (!button) return null

  return (
    <LinkButton
      to={button.url}
      target={button.target}
      className={classes.button}
      event_name={button.event_name}
      data-identity={button.identity}
      color="white"
    >
      {button.text}
    </LinkButton>
  )
}

const List = ({ items, title }) => {
  const classes = useStyles()

  return (
    <Cell xs={12} md={5}>
      <Box
        boxShadow={2}
        className={classes.listBox}
      >
        <Typography variant="subtitle1">
          {title}
        </Typography>
        {items.map((item) => (
          <div
            className={classes.listItem}
            key={item}
          >
            <Icon
              name="check_circle"
              size="1.5rem"
              color="darkGrey"
            />
            <Typography className={classes.marginLeftOne} variant="body1">
              {item}
            </Typography>
          </div>
        ))}
      </Box>
    </Cell>
  )
}

const Content = ({
  title,
  content,
  button,
}) => {
  const classes = useStyles()

  return (
    <Cell xs={12} md={7}>
      <Box className={cx(classes.flexOne, classes.contentContainer)}>
        <Typography variant="h3" color="white" className={classes.marginBottom}>
          {title}
        </Typography>

        <Typography
          html={content}
          component="div"
          variant="body1"
          color="white"
        />

        <Button button={button} />
      </Box>
    </Cell>
  )
}

const TwoColumnsCheckListBlock = ({
  list_title,
  list_content,
  title,
  content,
  button,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  // split by new line
  const listItems = list_content.split(/\n/g)

  return (
    <Block
      foregroundClassName={classes.blockForeground}
    >
      <Grid justify={isMobile ? 'center' : 'flex-start'}>
        {isMobile ? (
          <>
            <Content
              title={title}
              content={content}
            />
            <List
              title={list_title}
              items={listItems}
            />
            <Button button={button} />
          </>
        ) : (
          <>
            <List
              title={list_title}
              items={listItems}
            />
            <Content
              title={title}
              content={content}
              button={button}
            />
          </>
        )}
      </Grid>
    </Block>
  )
}

TwoColumnsCheckListBlock.propTypes = {
  list_title: PropTypes.string,
  list_content: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  button: PropTypes.array,
}

TwoColumnsCheckListBlock.defaultProps = {
  list_title: '',
  list_content: '',
  title: '',
  content: '',
  button: null,
}

export default TwoColumnsCheckListBlock
