export default (theme) => {
  const { maxContentWidth } = theme.global
  const { white, darkGrey } = theme.palette.common
  const { isLightColor } = theme.utils

  return {

    secondaryContainer: {
      margin: '0 auto 3rem',
      maxWidth: maxContentWidth,
      display: 'flex',
      flexDirection: 'column',
    },

    columnLayout: {
      flexDirection: 'row-reverse',
      marginTop: '4rem',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '0',
      },
    },

    headerText: {
      margin: '3rem auto 1rem auto',
      textAlign: 'center',
    },

    description: ({ backgroundColor }) => ({
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1rem',
      color: isLightColor(backgroundColor) ? darkGrey : white,
    }),

    video: {
      width: '600px',
      height: '338px', // keep 16:9 ratio
      margin: '0 auto',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '0',
        paddingBottom: '56.25%', // to keep the aspect ratio to 16:9
        '& iframe, & embed, & object': {
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
        },
      },
    },
  }
}
