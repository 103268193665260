import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import { BlockContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const EmbedVideoBlock = ({
  title,
  description,
  embed_content,
  layout,
  text_align,
}) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  return (
    <Block
      fullWidth
    >
      <Typography
        color={backgroundColor === 'primary' ? 'white' : 'primary'}
        variant="h3"
        component="h1"
        className={classes.headerText}
      >
        {title}
      </Typography>
      <div
        className={cx({
          [classes.secondaryContainer]: true,
          [classes.columnLayout]: layout !== 'default',
        })}
      >
        <Animation
          disabled
          animation="slideUp"
          offset="100"
          className={classes.description}
        >
          <div style={{ textAlign: text_align }}>
            <Typography
              component="div"
              html={description}
            />
          </div>
        </Animation>
        <Animation
          disabled
          animation="slideUp"
          offset="100"
        >
          <Typography
            component="div"
            className={classes.video}
            html={embed_content}
          />
        </Animation>
      </div>
    </Block>
  )
}

EmbedVideoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  embed_content: PropTypes.string.isRequired,
  layout: PropTypes.string,
  text_align: PropTypes.string,
}

EmbedVideoBlock.defaultProps = {
  description: '',
  layout: 'default',
  text_align: 'center',
}

export default EmbedVideoBlock
