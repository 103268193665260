import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'
import Image from '~/src/components/Image'
import { useBrowserSize } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const FullWidthImageBlock = ({
  image,
  background_overlay,
  title,
  height,
  responsive_image,
  ...props
}) => {
  const classes = useStyles()
  const size = useBrowserSize()
  const containerHeight = props[`${size}Height`] || height

  return (
    <Block
      fullWidth
      containerClassName={classes.mainContainer}
    >
      <div
        className={classes.header}
        style={{
          height: containerHeight || 'inherit',
        }}
      >
        <div className={cx(classes.imageContainer, {
          [classes.background]: !!title,
        })}
        >
          <Image
            src={image.src}
            alt={image.alt}
            className={classes.backgroundImage}
            responsiveImage={responsive_image}
          />
        </div>
        {background_overlay && <div className={cx(classes.background, classes.backgroundOverlay)} />}
        {!!title && (
          <div className={classes.headerContent}>
            {!!title && <Typography html={title} />}
          </div>
        )}
      </div>
    </Block>
  )
}

FullWidthImageBlock.propTypes = {
  image: PropTypes.object.isRequired,
  background_overlay: PropTypes.bool,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  responsive_image: PropTypes.object,
}

FullWidthImageBlock.defaultProps = {
  background_overlay: true,
  height: null,
  responsive_image: null,
}

export default FullWidthImageBlock
