export default (theme) => {
  const { white, darkGrey, primary } = theme.palette.common
  const { isLightColor } = theme.utils

  return {
    mainContainer: ({ backgroundColor }) => ({
      textAlign: 'center',
      color: isLightColor(backgroundColor) ? darkGrey : white,
    }),

    secondaryContainer: {
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 2, 4, 2),
      },
    },

    title: {
      marginTop: '3rem',
      marginBottom: '2rem',
    },

    itemsBlock: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
      },
    },

    item: {
      height: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),

      '&&': {
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          width: '50%',
          margin: '0.5rem auto',
          padding: theme.spacing(0),
        },
        [theme.breakpoints.down('sm')]: {
          width: '70%',
        },
        [theme.breakpoints.down('xs')]: {
          width: '95%',
        },
      },
    },

    label: {
      marginTop: 0,
      [theme.breakpoints.down('md')]: {
        alignSelf: 'center',
      },
    },

    image: {
      width: 'auto',
      maxWidth: '50%',
      height: 'auto',
      '&&': {
        fontSize: 'inherit',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '70%',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '95%',
      },
    },

    description: {
      marginTop: '1rem',
    },

    iconContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '7rem',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        fontSize: '2rem',
        minWidth: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1',
      },
    },

    inlineLabel: {
      margin: '1rem 1rem 0 1rem',
      [theme.breakpoints.down('md')]: {
        flex: '2',
        marginTop: 0,
      },
    },

    noDescriptionAndLink: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },

    buttonsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '2rem',
    },

    generalButton: {
      padding: theme.spacing(1.5, 4),
    },

    expandButton: {
      marginTop: '1rem',
    },

    expandButtonText: {
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
    },

    itemLinkContainer: {
      margin: theme.spacing(1.5, 0),
    },

    itemLink: {
      color: primary,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '1rem',
    },
  }
}
