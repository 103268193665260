import { Cell, Grid, Skeleton, Typography } from '@pickupp/ui/core'
import { makeStyles, useTheme } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { BROWSER } from '~/config'
import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import Image from '~/src/components/Image'
import LinkButton from '~/src/components/LinkButton'
import { BlockContext } from '~/src/contexts'
import { getTargetUrlWithPageUtmParams } from '~/src/services/utils'

import styles from './styles'

const useStyles = makeStyles(styles)

const Media = ({ media }) => {
  const classes = useStyles()
  const wrapperRef = useRef()
  const [contentSize, setContentSize] = useState({})
  const [iframeLoading, setIframeLoading] = useState(true)

  useEffect(() => {
    const iframe = wrapperRef.current?.getElementsByTagName('iframe')
    if (iframe?.length > 0) {
      // Append page utm params to iframe src
      iframe[0].src = getTargetUrlWithPageUtmParams(iframe[0]?.src?.trim())

      iframe[0].onload = () => {
        setIframeLoading(false)
      }
    } else {
      setIframeLoading(false)
    }
  }, [wrapperRef.current])

  useEffect(() => {
    if (BROWSER) {
      window.addEventListener('message', (event) => {
        if (event.data.contentSize) {
          setContentSize(event.data.contentSize)
        }
        if (event.data.redirect) {
          window.open(event.data.redirect, '_self')
        }
      })
    }
  }, [BROWSER])

  useEffect(() => {
    if (contentSize.height && BROWSER) {
      const iframe = wrapperRef.current?.getElementsByTagName('iframe')
      if (
        iframe?.length > 0 &&
        iframe[0].src.includes('pickupp.io') // only apply for pickupp iframe, apply for pages that have multiple media block
      ) {
        iframe[0].style.height = `${contentSize.height}px`
      }
    }
  }, [contentSize, wrapperRef.current, BROWSER])

  if (!media) return null

  if (media?.image?.src) {
    return (
      <Image
        src={media.image?.src}
        alt={media.image?.alt}
        imgClassName={classes.mediaImageSize}
      />
    )
  }

  return (
    <div>
      {iframeLoading && (
        <Grid
          justify="center"
          alignItems="center"
          spacing={0}
          className={classes.skeletonContainer}
        >
          <Cell xs={12} className={classes.skeleton}>
            <Skeleton animation="wave" width="50%" height="100px" />
            <Skeleton animation="wave" width="65%" height="40px" />
            <Skeleton animation="wave" width="40%" height="40px" />
            <Skeleton animation="wave" width="65%" height="40px" />
            <Skeleton animation="wave" width="40%" height="40px" />
          </Cell>
        </Grid>
      )}
      <div
        ref={wrapperRef}
        style={{
          display: iframeLoading ? 'none' : 'block',
        }}
      >
        <Typography
          component="div"
          html={media?.html_text}
        />
      </div>
    </div>
  )
}

Media.propTypes = {
  media: PropTypes.object.isRequired,
}

const TwoColumnsMediaBlock = ({
  title,
  content,
  buttons,
  media,
  twoColumnsMediaLayout: layout,
  background_image,
  background_overlay,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const { backgroundColor } = useContext(BlockContext)
  const isLightBackground = theme.utils.isLightColor(backgroundColor)
  const flipped = layout && layout.includes('flipped')
  const backgroundImage = background_image?.data?.attributes?.url

  return (
    <Block
      backgroundImage={backgroundImage}
      backgroundDimValue={background_overlay ? 0.6 : 0}
      foregroundClassName={cx(classes.columnContainer, {
        [classes.columnContainerDark]: !isLightBackground || !!backgroundImage,
        [classes.columnContainerFlipped]: flipped,
      })}
    >
      <div
        className={cx(classes.textColumn, {
          [classes.textColumnRight]: flipped,
        })}
      >
        <Animation
          disabled
          animation={flipped ? 'slideLeft' : 'slideRight'}
          delay="300"
          className={classes.textContent}
        >
          {!!title && (
            <Typography
              variant="h3"
              component="h1"
              className={classes.title}
              html={title}
            />
          )}
          {!!content && (
            <Typography html={content} />
          )}
          {!!buttons && buttons.map((link, index) => (
            <LinkButton
              key={link.text}
              to={link.url}
              color={index % 2 || (!isLightBackground && !backgroundImage) ? 'white' : 'primary'}
              data-identity={link.identity}
              className={classes.button}
              event_name={link.event_name}
              target={link.target}
            >
              {link.text}
            </LinkButton>
          ))}
        </Animation>
      </div>
      <Animation
        disabled
        animation={flipped ? 'slideRight' : 'slideLeft'}
        className={cx(classes.mediaColumn, {
          [classes.mediaHtml]: !media?.image?.src,
        })}
      >
        <Media media={media} />
      </Animation>
    </Block>
  )
}

TwoColumnsMediaBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  buttons: PropTypes.array,
  media: PropTypes.array,
  twoColumnsMediaLayout: PropTypes.array,
  background_image: PropTypes.string,
  background_overlay: PropTypes.bool,
}

TwoColumnsMediaBlock.defaultProps = {
  title: null,
  content: null,
  buttons: null,
  media: null,
  twoColumnsMediaLayout: null,
  background_image: null,
  background_overlay: false,
}

export default TwoColumnsMediaBlock
