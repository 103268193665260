import { Box, Cell, Grid, Typography } from '@pickupp/ui/core'
import { makeStyles, useTheme } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'

import { BROWSER } from '~/config'
import Block from '~/src/components/Block'
import Image from '~/src/components/Image'
import LinkButton from '~/src/components/LinkButton'
import { BlockContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const Card = ({
  title,
  content,
  image,
  link,
  layout,
}) => {
  const classes = useStyles()
  const isHorizontalLayout = layout === 'horizontal'

  return (
    <Cell
      xs={12}
      md={isHorizontalLayout ? 3 : 4}
      className={cx({
        [classes.cardHorizontal]: isHorizontalLayout,
        [classes.cardVertical]: !isHorizontalLayout,
      })}
    >
      <Box
        boxShadow={3}
        className={cx({
          [classes.boxHorizontal]: isHorizontalLayout,
          [classes.boxVertical]: !isHorizontalLayout,
        })}
      >
        <Grid
          direction="column"
          className={classes.cardContainer}
        >
          <Image
            src={image}
            className={cx(classes.cardImage, {
              [classes.imageExtended]: isHorizontalLayout,
            })}
            alt=""
          />
          <Grid direction="column" className={classes.cardContent}>
            <div>
              <Typography html={title} />
              <Typography
                className={cx(classes.itemContent, {
                  [classes.contentHorizontal]: isHorizontalLayout,
                })}
                html={content}
              />
            </div>
            {link && (
              <LinkButton
                target={link.target}
                className={cx({
                  [classes.buttonLinkHorizontal]: isHorizontalLayout,
                  [classes.buttonLinkVertical]: !isHorizontalLayout,
                })}
                buttonClassName={cx(classes.button, {
                  [classes.buttonVertical]: !isHorizontalLayout,
                })}
                to={link.url}
                data-identity={link.identity}
                color="primary"
                event_name={link.event_name}
              >
                {link.text}
              </LinkButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Cell>
  )
}

const ImageCardsBlock = ({
  title,
  card_items,
  imageCardsLayout: layout,
}) => {
  const theme = useTheme()
  const [windowWidth, setWindowWidth] = useState(0)
  const classes = useStyles({
    itemsLength: card_items.length,
    windowWidth,
  })
  const { backgroundColor } = useContext(BlockContext)

  const isHorizontalLayout = layout === 'horizontal'
  const isLightBackground = theme.utils.isLightColor(backgroundColor)
  const handleScreenResize = () => {
    setTimeout(() => setWindowWidth(window.innerWidth))
  }

  useEffect(() => {
    if (BROWSER) {
      setWindowWidth(window.innerWidth)
      window.addEventListener('resize', handleScreenResize)

      return () => {
        window.removeEventListener('resize', handleScreenResize)
      }
    }
    return null
  }, [BROWSER])

  return (
    <Block
      fullWidth={isHorizontalLayout}
      foregroundClassName={cx({
        [classes.horizontalBlock]: isHorizontalLayout,
        [classes.verticalBlock]: !isHorizontalLayout,
      })}
      containerClassName={classes.mainContainer}
    >
      <Typography
        html={title}
        variant="h2"
        color={isLightBackground ? 'black' : 'white'}
      />
      <Grid
        direction="row"
        spacing={!isHorizontalLayout ? 5 : 0}
        className={cx(classes.cardsContainer, {
          [classes.cardsHGrid]: isHorizontalLayout,
          [classes.cardsVGrid]: !isHorizontalLayout,
        })}
      >
        {card_items?.map((item) => (
          <Card
            layout={layout}
            key={item.title}
            image={item.item_image}
            {...item}
          />
        ))}
      </Grid>
    </Block>
  )
}

ImageCardsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  card_items: PropTypes.array.isRequired,
  imageCardsLayout: PropTypes.string.isRequired,
}

export default ImageCardsBlock
