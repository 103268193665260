import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import PropTypes from 'prop-types'
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Transition } from 'react-transition-group'

import { BROWSER } from '~/config'
import { AnimationContext } from '~/src/contexts'
import { useIsMobile } from '~/src/hooks'

import { makeTransitions } from './transitions'

const Animation = ({
  offset,
  className,
  duration,
  delay,
  animation,
  disabled,
  children,
}) => {
  // Transition style
  const [transition, setTransition] = useState(null)
  useEffect(() => {
    if (animation) {
      const transitions = makeTransitions({ duration, delay })
      setTransition(transitions[animation])
    }
  }, [animation, duration, delay])

  // Transition timeout
  const [timeout, setTimeout] = useState(0)
  useEffect(() => {
    setTimeout(Number(duration) + Number(delay))
  }, [duration, delay])

  // Reference to current element
  const ref = useRef()
  const [element, setElement] = useState(null)
  useEffect(() => {
    if (ref) {
      setElement(ref.current)
    }
  }, [ref])

  // Trigger transition for initial view and on scroll
  const [transitionIn, setTransitionIn] = useState(false)
  const isMobile = useIsMobile()
  const triggerTransition = () => {
    if (!transitionIn && element) {
      const windowOffset = isMobile ? 0 : Number(offset)
      const triggerPos = window.innerHeight - windowOffset
      const rect = element.getBoundingClientRect()
      if (rect.top <= triggerPos && rect.bottom > 0) {
        setTransitionIn(true)
      }
    }
  }
  useEffect(triggerTransition, [element])
  useScrollPosition(triggerTransition)

  // Additional transition logic for number animation
  const [number, setNumber] = useState(null)
  const [numberInterval, setNumberInterval] = useState(null)
  const onEnter = () => {
    if (animation !== 'number' || !element) return
    const node = element.childNodes[0]
    const matches = node.innerHTML.match(/[0-9.,]+/)
    if (!matches) return
    // Start from 0
    const current = 0
    // Final number to reach
    const final = Number(matches[0].replace(/,/g, ''))
    // Must be 1 or above to increment per tick
    const ticks = 100
    const increment = Math.round(final / ticks)
    const step = Math.max(increment, 1)

    setNumber({ current, step, final })
  }
  useEffect(() => {
    if (!number) return
    if (!numberInterval) {
      const run = () => setNumber((n) => {
        // Incremented number cannot exceed final number
        const current = Math.min(n.current + n.step, n.final)
        // Format number into string
        const numberString = String(current).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        // Replace number in node html
        const node = element.childNodes[0]
        const [match] = node.innerHTML.match(/[0-9.,]+/)
        node.innerHTML = node.innerHTML.replace(match, numberString)
        return {
          ...n,
          current,
        }
      })

      const interval = setInterval(() => {
        run()
      }, (3000 / number.final) * number.step) // show animation in 3 seconds
      run()

      setNumberInterval(interval)
      return
    }
    if (number.current >= number.final) {
      clearInterval(numberInterval)
    }
  }, [number, numberInterval])

  // Disable animation
  const { animationEnabled } = useContext(AnimationContext)
  if (disabled || !animationEnabled) {
    // Hide everything if animation is disabled globally
    // and this is build time
    const style = !BROWSER && !disabled ? {
      visibility: 'hidden',
    } : null

    return (
      <div
        className={className}
        style={style}
        data-animation={animation}
      >
        {children}
      </div>
    )
  }

  return (
    <Transition
      in={transitionIn}
      timeout={timeout}
      onEnter={onEnter}
    >
      {(state) => (
        <div
          ref={ref}
          className={className}
          style={transition ? {
            ...transition.defaultStyle,
            ...transition.transitionStyles[state],
          } : null}
          data-animation={animation}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}

Animation.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  duration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  delay: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  animation: PropTypes.oneOf([
    'number',
    'fade',
    'pop',
    'slideUp',
    'slideLeft',
    'slideRight',
  ]),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Animation.defaultProps = {
  className: null,
  offset: 200,
  duration: 400,
  delay: 0,
  animation: 'fade',
  disabled: false,
}

export default Animation
