import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Icon from '~/src/components/Icon'
import LinkButton from '~/src/components/LinkButton'

import styles from './styles'

const useStyles = makeStyles(styles)

const Links = ({ links }) => {
  const classes = useStyles()

  return (
    <div>
      {links.map(({ icon, link }, index) => {
        if (!link) return null
        const {
          url,
          target,
          text,
          identity,
          event_name,
        } = link
        return (
          <div key={`link_${index}`} className={classes.button}>
            <LinkButton
              to={url}
              target={target}
              data-identity={identity}
              buttonClassName={classes.linkButton}
              event_name={event_name}
            >
              {icon && <Icon icon={icon} />}
              {text}
            </LinkButton>
          </div>
        )
      })}
    </div>
  )
}

Links.propTypes = {
  links: PropTypes.array.isRequired,
}

export default Links
