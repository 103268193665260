import PropTypes from 'prop-types'
import React from 'react'

import { BlockContext } from '~/src/contexts'

import ComponentBlockBubbleBlock from '../BubbleBlock'
import ComponentBlockCardsBlock from '../CardsBlock'
import ComponentBlockCarouselBlock from '../CarouselBlock'
import ComponentBlockCatalogBlock from '../CatalogBlock'
import ComponentBlockDeliverBlock from '../DeliverBlock'
import ComponentBlockEmbedVideoBlock from '../EmbedVideoBlock'
import ComponentBlockExpandBlock from '../ExpandBlock'
import ComponentBlockExpandBlockV2 from '../ExpandV2Block'
import ComponentBlockFormattedTableBlock from '../FormattedTableBlock'
import ComponentBlockFormBlock from '../FormBlock'
import ComponentBlockFullWidthImageBlock from '../FullWidthImageBlock'
import ComponentBlockIconCardsBlock from '../IconCardsBlock'
import ComponentBlockIframeBlock from '../IframeBlock'
import ComponentBlockImageBlock from '../ImageBlock'
import ComponentBlockImageCardsBlock from '../ImageCardsBlock'
import ComponentBlockImageCarouselBlock from '../ImageCarouselBlock'
import ComponentBlockItemsBlock from '../ItemsBlock'
import ComponentBlockLatestPostsBlock from '../LatestPostsBlock'
import ComponentBlockListBlock from '../ListBlock'
import ComponentBlockParagraphsBlock from '../ParagraphsBlock'
import ComponentBlockRegistrationBlock from '../RegistrationBlock'
import ComponentBlockSimpleTextBlock from '../SimpleTextBlock'
import ComponentBlockSliderBlock from '../SliderBlock'
import ComponentBlockSpaceBlock from '../SpaceBlock'
import ComponentBlockTabsBlock from '../TabsBlock'
import ComponentBlockThreeImagesBlock from '../ThreeImagesBlock'
import ComponentBlockThreeStepsBlock from '../ThreeStepsBlock'
import ComponentBlockTwoColumnsBlock from '../TwoColumnsBlock'
import ComponentBlockTwoColumnsCheckListBlock from '../TwoColumnsCheckListBlock'
import ComponentBlockTwoColumnsImageBlock from '../TwoColumnsImageBlock'
import ComponentBlockTwoColumnsMediaBlock from '../TwoColumnsMediaBlock'

// NOTE: Block name is based on Prime block type name
const blockMap = {
  ComponentBlockBubbleBlock,
  ComponentBlockCardsBlock,
  ComponentBlockCarouselBlock,
  ComponentBlockCatalogBlock,
  ComponentBlockDeliverBlock,
  ComponentBlockEmbedVideoBlock,
  ComponentBlockExpandBlock,
  ComponentBlockExpandBlockV2,
  ComponentBlockFormattedTableBlock,
  ComponentBlockFormBlock,
  ComponentBlockFullWidthImageBlock,
  ComponentBlockIconCardsBlock,
  ComponentBlockIframeBlock,
  ComponentBlockImageBlock,
  ComponentBlockImageCardsBlock,
  ComponentBlockImageCarouselBlock,
  ComponentBlockItemsBlock,
  ComponentBlockLatestPostsBlock,
  ComponentBlockListBlock,
  ComponentBlockParagraphsBlock,
  ComponentBlockRegistrationBlock,
  ComponentBlockSimpleTextBlock,
  ComponentBlockSliderBlock,
  ComponentBlockSpaceBlock,
  ComponentBlockTabsBlock,
  ComponentBlockThreeImagesBlock,
  ComponentBlockThreeStepsBlock,
  ComponentBlockTwoColumnsBlock,
  ComponentBlockTwoColumnsCheckListBlock,
  ComponentBlockTwoColumnsImageBlock,
  ComponentBlockTwoColumnsMediaBlock,
}

const SharedBlock = ({ block }) => {
  const blocks = block?.data?.attributes?.blocks || []
  if (blocks.length < 1) return null

  return (
    <>
      {blocks.map((data, index) => {
        const stripPrefix = (string) => string.replace(/^Strapi_/, '')
        const typeName = stripPrefix(data.__typename)
        const PageBlock = blockMap[typeName]
        if (!PageBlock) return null

        const {
          background_color,
          gradient,
          transparent,
          identity,
        } = data.option || {}
        const blockContext = {
          data,
          typeName,
          backgroundColor: background_color,
          gradient,
          transparent,
          identity,
        }

        return (
          <BlockContext.Provider
            key={`${index}_${data.__typename}`}
            value={blockContext}
          >
            <PageBlock {...data} key={typeName} />
          </BlockContext.Provider>
        )
      })}
    </>
  )
}

SharedBlock.propTypes = {
  block: PropTypes.object.isRequired,
}

export default SharedBlock
