import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const useStyles = makeStyles(styles)

const RoundedImage = ({
  image,
  width,
  className,
}) => {
  const classes = useStyles({
    width,
  })

  return (
    <div className={cx(className, classes.mainContainer)}>
      <img
        loading="lazy"
        src={image.src.data.attributes.url}
        alt={image.alt}
        className={classes.image}
      />
    </div>
  )
}

RoundedImage.propTypes = {
  image: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  className: PropTypes.string,
}

RoundedImage.defaultProps = {
  className: '',
}

export default RoundedImage
