import { Icon, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Block from '~/src/components/Block'

import styles from './styles'

const useStyles = makeStyles(styles)

const Item = ({ item, index }) => {
  const classes = useStyles()
  const [activeRow, setActiveRow] = useState('0_0')
  return (
    <div>
      <div
        className={cx({
          [classes.question]: true,
          [classes.primaryColor]: activeRow === index,
        })}
        onClick={() => setActiveRow(index === activeRow ? null : index)}
      >
        <Typography weight={600}>
          {item.header}
        </Typography>
        <Icon
          name={activeRow === index ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
        />
      </div>
      <div
        className={cx({
          [classes.answer]: true,
          [classes.activeAnswer]: activeRow === index,
        })}
      >
        <Typography
          variant="body2"
          html={item.content}
          className={classes.answerContent}
        />
      </div>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
}

const Section = ({ section, index }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography
        className={classes.title}
        variant="h5"
        component="h1"
      >
        {section.title}
      </Typography>
      {section.items.map((item, itemIndex) => {
        const globalIndex = `${index}_${itemIndex}`
        return (
          <Item
            key={itemIndex}
            item={item}
            index={globalIndex}
          />
        )
      })}
    </div>
  )
}

Section.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

const ExpandBlock = ({
  header,
  sections,
}) => {
  const classes = useStyles()

  return (
    <Block
      fullWidth
      foregroundClassName={classes.foregroundContainer}
      containerClassName={cx(classes.mainContainer, classes.body)}
    >
      {!!header && (
        <Typography
          className={classes.header}
          variant="h3"
          color="primary"
        >
          {header}
        </Typography>
      )}
      {sections.map((section, index) => (
        <Section
          key={`section-${index}`}
          index={index}
          section={section}
        />
      ))}
    </Block>
  )
}

ExpandBlock.propTypes = {
  header: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
}

export default ExpandBlock
