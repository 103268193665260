export default () => {
  return {
    button: {
      marginTop: '1rem',
    },

    linkButton: {
      minWidth: '9rem',
    },
  }
}
