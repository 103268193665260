export default (theme) => {
  const { white, primary } = theme.palette.common
  const { isLightColor } = theme.utils

  return {
    header: {
      textAlign: 'center',
      margin: '0 1rem 3rem 1rem',
      '& h4': {
        marginBottom: '1rem',
      },
    },

    headerText: ({ backgroundColor }) => ({
      color: isLightColor(backgroundColor) ? primary : white,
    }),
  }
}
