import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'
import Form from '~/src/components/Form'

import styles from './styles'

const useStyles = makeStyles(styles)

const FormBlock = ({ form }) => {
  const classes = useStyles()

  return (
    <Block>
      <div className={classes.formContainer}>
        <Form data={form} />
      </div>
    </Block>
  )
}

FormBlock.propTypes = {
  form: PropTypes.object.isRequired,
}

export default FormBlock
