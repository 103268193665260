import ListStyle from '~/src/assets/list-style.png'

export default (theme) => {
  const { white } = theme.palette.common

  return {
    mainContainer: {
      backgroundColor: white,
      margin: '3rem auto 7rem auto',
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(4, 0),
      },
    },

    secondaryContainer: ({ layout }) => ({
      display: 'flex',
      flexDirection: layout.includes('Flipped') ? 'row-reverse' : 'row',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    }),

    imageContainer: {
      flex: 1,
      margin: '1rem',
    },

    textContainer: ({ layout }) => ({
      marginLeft: '3rem',
      flex: layout.includes('fullWidth') ? 1 : 2,
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        marginLeft: 0,
      },
    }),

    textSpacing: {
      margin: '1rem 0',
    },

    li: {
      listStyleImage: `url(${ListStyle})`,
    },

    ul: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
        margin: '3rem 6rem',
      },
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(2),
      },
    },

    button: {
      marginTop: theme.spacing(4),
    },

    borderRadius: {
      borderRadius: theme.spacing(2),
    },

    image: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        width: 'auto',
        maxWidth: '320px',
        margin: '0 auto',
      },
    },
  }
}
