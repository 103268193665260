export default (theme) => {
  return {
    foregroundContainer: {
      display: 'flex',
      margin: '3rem auto 3rem auto',
    },

    columnLeft: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginRight: '1rem',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    image: {
      margin: '1rem',
    },

    columnRight: {
      flex: 1,
      marginLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        width: '80%',
        margin: '2rem auto',
      },
    },

    bubbleContainer: {
      position: 'relative',
      '& ol': {
        textAlign: 'left',
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& > img': {
          maxWidth: '80%',
        },
      },
    },

    bubbleText: {
      position: 'absolute',
      top: '55px',
      left: '90px',
      [theme.breakpoints.down('md')]: {
        left: 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        top: '2rem',
      },
    },

    content: {
      width: '80%',
      marginBottom: '2rem',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  }
}
