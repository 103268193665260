export default (theme) => {
  return {
    mainContainer: {
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 2),
      },
    },

    image: {
      width: '100%',
      height: 'auto',
    },
  }
}
