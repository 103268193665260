import { isWidthUp, Typography, useWidth } from '@pickupp/ui/core'
import { makeStyles, useTheme } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import Link from '~/src/components/Link'

import styles from './styles'

const useStyles = makeStyles(styles)

const CatalogBlock = ({ title, products }) => {
  const classes = useStyles()
  const width = useWidth(useTheme())
  const notMobile = isWidthUp('sm', width)

  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Block className={classes.mainContainer}>
      {title && (
        <Animation
          disabled
          animation="slideUp"
        >
          <Typography
            className={classes.title}
            component="div"
            html={title}
          />
        </Animation>
      )}
      <ReactCarousel
        autoPlay
        infiniteLoop
        centerMode
        centerSlidePercentage={notMobile ? 30 : 60}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        swipeable={false}
        className={classes.itemContainer}
        selectedItem={selectedIndex}
        onChange={setSelectedIndex}
      >
        {products.map((item, index) => {
          const image = item.image && (
            <img
              loading="lazy"
              src={item.image.src?.data?.attributes?.url}
              alt={item.image.alt}
              className={classes.image}
            />
          )

          const selected = selectedIndex === index

          return (
            <div
              key={index}
              className={classes.item}
              style={selected ? {
                opacity: 1,
              } : null}
              onClick={() => {
                setSelectedIndex(index)
              }}
            >
              {selected && item.link ? (
                <Link
                  to={item.link.url}
                  alt={item.link.text}
                  target={item.link.target}
                  data-identity={item.link.identity}
                  className={classes.link}
                  event_name={item.link.event_name}
                >
                  {image}
                </Link>
              ) : image}
            </div>
          )
        })}
      </ReactCarousel>
    </Block>
  )
}

CatalogBlock.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array,
}

CatalogBlock.defaultProps = {
  title: null,
  products: [],
}

export default CatalogBlock
