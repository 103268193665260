export default (theme) => {
  const { white, darkGrey } = theme.palette.common
  const { isLightColor } = theme.utils

  return {
    columns: ({ layout, textAlign }) => ({
      display: 'flex',
      justifyContent: 'center',
      flexDirection: layout === 'flipped' ? 'row-reverse' : 'row',
      flex: 1,
      textAlign: textAlign ? textAlign[0] : 'center',
      [theme.breakpoints.down('md')]: {
        margin: '0 1.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    }),

    column: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '&:first-child': {
        marginRight: '1rem',
      },
      '&:last-child': {
        marginLeft: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        '&:first-child': {
          marginRight: 0,
        },
        '&:last-child': {
          marginLeft: 0,
        },
      },
    },

    imageContainer: {
      margin: '1rem',
    },

    image: {
      display: 'block',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        maxWidth: '20rem',
        margin: '0 auto',
      },
      [theme.breakpoints.up('sm')]: {
        width: 482,
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 320,
        minHeight: 180,
      },
      [theme.breakpoints.only('xs')]: {
        minWidth: 280,
        minHeight: 157,
      },
    },

    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginTop: '1rem',
      },
    },

    row: ({ backgroundColor }) => ({
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      color: isLightColor(backgroundColor) ? darkGrey : white,
    }),

    borderRadius: {
      borderRadius: '1rem',
    },
  }
}
