export default (theme) => {
  return {
    mainContainer: {
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    },

    textSpacing: {
      margin: theme.spacing(2, 0),
    },

    description: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(4),
        marginTop: 0,
      },
    },

    button: {
      minWidth: '2rem',
      margin: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(1),
      },
    },

    innerButton: {
      minWidth: '9rem',
    },

    buttons: {
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  }
}
