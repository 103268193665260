export default (theme) => {
  return {
    contentContainer: {
      padding: '0 4rem',
      [theme.breakpoints.down('md')]: {
        padding: '0 2rem',
      },
    },

    authorImageContainer: {
      marginTop: '0rem',
      minHeight: '375px',
      minWidth: '375px',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '0 2rem',
        minHeight: '200px',
        minWidth: '200px',
      },
    },

    titleContainer: {
      marginBottom: '2rem',
      padding: '0 2rem',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        padding: '0',
      },
    },

    arrow: {
      position: 'relative',
      top: 50,
      [theme.breakpoints.down('md')]: {
        top: 30,
      },
    },

    sliderContent: {
      padding: '0 1rem',
      minHeight: '100px',
      flex: 1,
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },

    authorContainer: {
      padding: '0 2.5rem',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },

    marginBottomTwo: {
      marginBottom: '2rem',
    },

    button: {
      marginTop: '1rem',
    },

    imagesContainer: {
      marginTop: 40,
      marginBottom: 20,
    },

    imageContainer: {
      width: 168,
      margin: '8px 20px',
      '& picture': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }
}
