export default (theme) => {
  const {
    primary,
    white,
    darkGrey,
  } = theme.palette.common
  const { maxContentWidth } = theme.global

  return {
    iframe: {
      margin: '-3rem auto 2rem auto',
      display: 'flex',
      width: '100%',
      height: '480px',
      border: 'none',
    },

    header: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },

    contentContainer: {
      maxWidth: maxContentWidth,
      margin: '0px auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        height: 'unset',
      },
    },

    primaryContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '3rem 1rem',
      flex: 1,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: '2rem 0 0',
      },
    },

    secondaryContainer: {
      padding: '1rem 2rem 1rem 4rem',
      borderRadius: '1rem',
      backgroundColor: primary,
      marginTop: '-4rem',
      marginRight: '4rem',
      position: 'relative',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        padding: '1rem 2.5rem',
        marginRight: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        backgroundColor: 'unset',
        margin: '0 auto',
      },
    },

    textSpacing: {
      color: white,
      [theme.breakpoints.down('xs')]: {
        color: darkGrey,
      },
    },
  }
}
