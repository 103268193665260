export default (theme) => {
  return {
    mainContainer: {
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 4),
      },
      '&& .slider.animated': {
        '& > li:first-child, & > li:last-child': {
          visibility: 'hidden',
        },
      },
    },

    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },

    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& .carousel': {
        paddingBottom: theme.spacing(4),
        '& .dot': {
          outline: 'none',
          background: theme.palette.common.primary,
        },
        '& .control-dots': {
          paddingLeft: 0,
        },
      },
    },

    item: {
      padding: theme.spacing(1),
      flexGrow: 0,
      position: 'relative',
      opacity: 0.5,
      cursor: 'pointer',
      '&:hover': {
        opacity: 1,
      },
    },

    link: {
      display: 'block',
    },

    image: {
      width: '100%',
      height: 'auto',
    },
  }
}
