import { Typography } from '@pickupp/ui/core'
import { makeStyles, useTheme } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import LinkButton from '~/src/components/LinkButton'
import RoundedImage from '~/src/components/RoundedImage'
import { BlockContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const TwoColumnsImageBlock = ({
  content,
  buttons,
  alignment,
  image,
  twoColumnsImageLayout: layout,
}) => {
  const { backgroundColor } = useContext(BlockContext)
  const flipped = layout && layout.includes('flipped')

  const classes = useStyles({
    backgroundColor,
    flipped,
    alignment,
  })
  const theme = useTheme()

  return (
    <Block
      foregroundClassName={classes.columnContainer}
    >
      {image && (
        <Animation
          disabled
          animation={flipped ? 'slideLeft' : 'slideRight'}
          className={classes.imageColumn}
        >
          <RoundedImage
            className={classes.imageContainer}
            image={image}
            width="90%"
          />
        </Animation>
      )}
      <div
        className={classes.textColumn}
      >
        <Animation
          disabled
          animation={flipped ? 'slideRight' : 'slideLeft'}
          delay="300"
          className={classes.textContent}
        >
          <Typography
            component="div"
            html={content}
          />
          {buttons?.length > 0 && (
            <LinkButton
              to={buttons[0].url}
              color={theme.utils.isLightColor(backgroundColor) ?
                'primary' : 'white'}
              data-identity={buttons[0].identity}
              className={classes.button}
              event_name={buttons[0].event_name}
              target={buttons[0].target}
            >
              {buttons[0].text}
            </LinkButton>
          )}
        </Animation>
      </div>
    </Block>
  )
}

TwoColumnsImageBlock.propTypes = {
  content: PropTypes.string.isRequired,
  buttons: PropTypes.array,
  image: PropTypes.object,
  alignment: PropTypes.array,
  twoColumnsImageLayout: PropTypes.array,
}

TwoColumnsImageBlock.defaultProps = {
  buttons: null,
  image: null,
  alignment: null,
  twoColumnsImageLayout: null,
}

export default TwoColumnsImageBlock
