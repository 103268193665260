export default (theme) => {
  const { black } = theme.palette.common
  const { maxContentWidth } = theme.global

  return {
    mainContainer: {
      position: 'relative',
      overflow: 'hidden',
      zIndex: 5,
    },

    header: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    imageContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },

    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    backgroundImage: {
      margin: 0,
      width: '100%',
      height: '100%',
      '& picture, & img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },

    backgroundOverlay: {
      backgroundColor: black,
      opacity: 0.35,
    },

    headerContent: {
      maxWidth: maxContentWidth,
      margin: '0 auto',
      padding: '10rem 0',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        padding: '4rem 1.5rem',
        textAlign: 'center',
      },
    },
  }
}
