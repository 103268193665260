import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'

import Body from './Body'
import Header from './Header'

const TwoColumnsBlock = (props) => {
  const { header, subheader, ...otherProps } = props

  return (
    <Block>
      <Header
        header={header}
      />
      <Body {...otherProps} />
    </Block>
  )
}

TwoColumnsBlock.propTypes = {
  header: PropTypes.string,
}

TwoColumnsBlock.defaultProps = {
  header: null,
}

export default TwoColumnsBlock
