export default (theme) => {
  const { maxContentWidth } = theme.global

  return {
    mainContainer: {
      maxWidth: maxContentWidth,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4, 0),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 2),
      },
    },

    contentContainer: {
      padding: theme.spacing(4, 0),
    },

    textContainer: {
      padding: theme.spacing(1, 0),
    },

    headerText: {
      fontWeight: 'bold',
      alignSelf: 'center',
    },

    bodyText: {
      fontWeight: 'normal',
    },
  }
}
