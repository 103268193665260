import {
  Button,
  Icon,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'

import Block from '~/src/components/Block'
import ImageLink from '~/src/components/ImageLink'
import LinkButton from '~/src/components/LinkButton'
import { BlockContext } from '~/src/contexts'
import { useIsMobile } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const CTAButton = ({ button }) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  if (!button) return null

  return (
    <LinkButton
      to={button.url}
      target={button.target}
      className={classes.button}
      event_name={button.event_name}
      data-identity={button.identity}
    >
      {button.text}
    </LinkButton>
  )
}

const CustomArrowButton = ({ direction, onClick, rootClassName }) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  return (
    <Button
      variant="text"
      onClick={onClick}
      className={cx(classes.arrowButton, rootClassName)}
      disableRipple
    >
      <Icon name={`keyboard_arrow_${direction}`} size="24px" />
    </Button>
  )
}

const CustomIndicators = ({ onSelect, rootClassName, items, selectedIndex }) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  const onItemSelected = (itemIndex) => {
    onSelect(itemIndex)
  }

  return (
    <div className={cx(classes.indicators, rootClassName)}>
      {
        items.map((item, index) => (
          <Icon
            key={index}
            name="circle"
            size="8px"
            onClick={() => { onItemSelected(index) }}
            className={cx(classes.indicator, {
              [classes.selectedIndicator]: (selectedIndex === index),
            })}
          />
        ))
      }
    </div>
  )
}

const ImageCarouselBlock = ({
  title,
  subtitle,
  infinite_loop,
  autoplay,
  interval,
  carousel_items,
  button,
}) => {
  const { backgroundColor } = useContext(BlockContext)
  const isMobile = useIsMobile()
  const classes = useStyles({ backgroundColor, isMobile })
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Block foregroundClassName={classes.blocksContainer}>
      <Typography
        variant="h4"
        component="h1"
        className={classes.title}
      >
        {title}
      </Typography>
      <Typography
        className={classes.subtitle}
        html={subtitle}
      />
      <div className={classes.carouselWrapper}>
        <Carousel
          className={classes.carouselImages}
          infiniteLoop={infinite_loop}
          autoPlay={autoplay}
          interval={interval || 3000}
          showStatus={false}
          showThumbs={false}
          swipeable={isMobile}
          showArrows={false}
          showIndicators={false}
          selectedItem={selectedIndex}
          onChange={setSelectedIndex}
        >
          {carousel_items.map((item, i) => (
            <ImageLink
              key={i}
              imgStyles={classes.carouselImg}
              {...item}
            />
          ))}
        </Carousel>
        <CustomArrowButton
          direction="left"
          rootClassName={cx(classes.arrowButtonPrev, {
            [classes.hide]: !infinite_loop && (selectedIndex <= 0),
          })}
          onClick={() => {
            setSelectedIndex((prevIndex) => (prevIndex - 1))
          }}
        />
        <CustomArrowButton
          direction="right"
          rootClassName={cx(classes.arrowButtonNext, {
            [classes.hide]: !infinite_loop && (selectedIndex >= (carousel_items.length - 1)),
          })}
          onClick={() => {
            setSelectedIndex((prevIndex) => (prevIndex + 1))
          }}
        />
        <CustomIndicators
          rootClassName={classes.customIndicators}
          items={carousel_items}
          onSelect={(selected) => {
            setSelectedIndex(selected)
          }}
          selectedIndex={selectedIndex}
        />
      </div>
      <CTAButton button={button} />
    </Block>
  )
}

ImageCarouselBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  infinite_loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  carousel_items: PropTypes.array.isRequired,
  button: PropTypes.object,
}

ImageCarouselBlock.defaultProps = {
  title: '',
  subtitle: '',
  infinite_loop: true,
  autoplay: false,
  button: null,
}

export default ImageCarouselBlock
