import { Box, Cell, Grid, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import Icon from '~/src/components/Icon'
import LinkButton from '~/src/components/LinkButton'

import styles from './styles'

const useStyles = makeStyles(styles)

const Card = ({ item, layout }) => {
  const classes = useStyles()

  return (
    <Cell xs={12} md={layout === 'default' ? true : 4}>
      <Animation animation="pop" className={classes.fullHeight}>
        <Box boxShadow={3} className={classes.fullHeight}>
          <Grid
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            className={classes.box}
          >
            <div className={classes.boxContent}>
              <Grid
                direction="row"
                alignItems="center"
                wrap="nowrap"
                className={classes.boxHeader}
              >
                {item.icon && (
                  <div className={classes.icon}>
                    <Icon
                      className={classes.boxIcon}
                      {...item.icon}
                    />
                  </div>
                )}
                <Typography
                  className={classes.boxTitle}
                  variant="h4"
                  component="h2"
                >
                  {item.title}
                </Typography>
              </Grid>
              <Typography
                className={classes.row}
                html={item.content}
              />
            </div>
            <LinkButton
              to={item.button.url}
              data-identity={item.button.identity}
              className={classes.button}
              color="primary"
              event_name={item.button.event_name}
            >
              {item.button.text}
            </LinkButton>
          </Grid>
        </Box>
      </Animation>
    </Cell>
  )
}

Card.propTypes = {
  item: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
}

const IconCardsBlock = ({
  header,
  cards,
  icon_cards_layout,
}) => {
  const classes = useStyles()

  return (
    <Block containerClassName={classes.mainContainer}>
      <Animation
        disabled
        animation="slideUp"
      >
        <Typography
          html={header}
          component="div"
        />
      </Animation>
      <Grid
        justify="center"
        spacing={2}
        className={classes.cards}
      >
        {cards.map((item) => (
          <Card
            key={item.title}
            item={item}
            layout={icon_cards_layout}
          />
        ))}
      </Grid>
    </Block>
  )
}

IconCardsBlock.propTypes = {
  header: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
  icon_cards_layout: PropTypes.string,
}

IconCardsBlock.defaultProps = {
  icon_cards_layout: 'default',
}

export default IconCardsBlock
