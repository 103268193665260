export default (theme) => {
  return {
    title: {
      textAlign: 'center',
      fontSize: '50px',
    },
    imageContainer: {
      textAlign: 'center',
    },
    imageTextContainer: {
      width: '75%',
      margin: '0 auto',
    },
    image: {
      borderRadius: '0.5rem',
      margin: 'auto',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 385,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 601,
      },
      [theme.breakpoints.only('xs')]: {
        maxWidth: 428,
      },
    },
    label: {
      fontSize: '18px',
      fontWeight: '700',
      marginBottom: '0.5rem',
    },
    description: {
      fontSize: '14px',
    },
  }
}
