export default (theme) => {
  return {
    mainContainer: ({ width }) => {
      return {
        position: 'relative',
        width,
        '& img': {
          borderRadius: '1rem',
        },
      }
    },
    image: {
      display: 'block',
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        width: 'auto',
        maxWidth: '320px',
        margin: '0 auto',
      },
    },
  }
}
