// http://reactcommunity.org/react-transition-group/transition

const makeTransitions = ({ duration, delay }) => {
  const baseDefaultStyle = {
    transition: `all ${duration}ms ease ${delay}ms`,
  }

  const transitions = {
    number: {
      defaultStyle: {
        ...baseDefaultStyle,
      },
      transitionStyles: {
        entering: null,
        entered: null,
        exiting: null,
        exited: null,
      },
    },

    fade: {
      defaultStyle: {
        ...baseDefaultStyle,
        opacity: 0,
      },
      transitionStyles: {
        entering: {
          opacity: 1,
        },
        entered: {
          opacity: 1,
        },
        exiting: null,
        exited: null,
      },
    },

    pop: {
      defaultStyle: {
        ...baseDefaultStyle,
        transform: 'scale(0.5)',
      },
      transitionStyles: {
        entering: {
          transform: 'scale(1)',
        },
        entered: {
          transform: 'scale(1)',
        },
        exiting: null,
        exited: null,
      },
    },

    slideUp: {
      defaultStyle: {
        ...baseDefaultStyle,
        transform: 'translate(0, 7rem)',
        opacity: 0,
      },
      transitionStyles: {
        entering: {
          transform: 'translate(0, 0)',
          opacity: 1,
        },
        entered: {
          transform: 'translate(0, 0)',
          opacity: 1,
        },
        exiting: null,
        exited: null,
      },
    },

    slideLeft: {
      defaultStyle: {
        ...baseDefaultStyle,
        transform: 'translate(50%, 0)',
        opacity: 0,
      },
      transitionStyles: {
        entering: {
          transform: 'translate(0, 0)',
          opacity: 1,
        },
        entered: {
          transform: 'translate(0, 0)',
          opacity: 1,
        },
        exiting: null,
        exited: null,
      },
    },

    slideRight: {
      defaultStyle: {
        ...baseDefaultStyle,
        transform: 'translate(-50%, 0)',
        opacity: 0,
      },
      transitionStyles: {
        entering: {
          transform: 'translate(0, 0)',
          opacity: 1,
        },
        entered: {
          transform: 'translate(0, 0)',
          opacity: 1,
        },
        exiting: null,
        exited: null,
      },
    },
  }

  return transitions
}

export {
  makeTransitions,
}
