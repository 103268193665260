export default (theme) => {
  const { white } = theme.palette.common
  const { maxContentWidth } = theme.global
  const contentWidth = maxContentWidth / 2
  const daImageMinHeight = 690

  return {
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
    },

    primaryContent: {
      minHeight: daImageMinHeight,
      maxWidth: maxContentWidth * 1.2,
      width: '100%',
      display: 'flex',
      padding: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        minHeight: 'unset',
        padding: theme.spacing(2, 0),
      },
    },

    leftSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    formContainer: {
      maxWidth: contentWidth,
      padding: theme.spacing(0, 3),
      color: white,
      flex: 1,
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
        margin: 'auto',
        maxWidth: '90%',
        padding: 0,
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      },
    },

    textSpacing: {
      margin: theme.spacing(2, 0),
    },

    rightSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    imageContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0, 3),
      '& picture, & img': {
        width: '100%',
        objectFit: 'contain',
        borderRadius: '1rem',
      },
    },
  }
}
