import externalLinkIcon from '~/src/assets/external_link_icon.png'

export default (theme) => {
  const { spacing, palette, breakpoints } = theme
  return {
    mainContainer: {
      textAlign: 'center',
      padding: spacing(7, 15),
      [breakpoints.down('md')]: {
        padding: spacing(7, 12),
      },
      [breakpoints.down('sm')]: {
        padding: spacing(7, 8),
      },
    },
    subtitle: {
      marginTop: spacing(1),
      [theme.breakpoints.down('md')]: {
        margin: '1rem 2rem',
      },
    },
    table: {
      height: 1,
      tableLayout: 'fixed',
      margin: spacing(3, 0),
      [breakpoints.down('md')]: {
        padding: spacing(0, 3),
      },
    },
    tableRow: {
      height: '100%',
      '&:nth-child(2n+4)': {
        background: palette.common.fadeGrey,
      },
    },
    extendedTableRow: {
      height: '100%',
      borderBottom: `2px solid ${palette.common.fadeGrey}`,
    },
    tableCol: {
      height: '100%',
      margin: spacing(0, 1),
      padding: spacing(0, 1),
      border: 'none',
      textAlign: 'center',
    },
    defaultTableCol: {
      '&:first-child ': {
        border: 'none',
        width: 270,
        paddingRight: 0,
        '& $tableColContent': {
          width: '100%',
          justifyContent: 'flex-end',
          textAlign: 'right',
        },
        '& p': {
          fontWeight: 'bold',
        },
        '& a': {
          color: palette.common.darkGrey,
          '&:after': {
            content: `url('${externalLinkIcon}')`,
            marginLeft: 5,
          },
        },
      },
    },
    extendedTableCol: {
      '& $tableColContent': {
        padding: spacing(2),
      },
      '&:not(:first-child)': {
        '& p': {
          lineHeight: spacing(4),
        },
      },
      '&:first-child ': {
        textAlign: 'left',
        width: 240,
        '& $tableColContent': {
          justifyContent: 'flex-start',
          '& p': {
            marginLeft: spacing(2),
          },
        },
      },
    },
    colImageWrapper: {
      width: 40,
      height: 40,
    },
    colImage: {
      margin: 0,
    },
    tableColContent: {
      height: '100%',
      padding: spacing(1, 2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: '0px .5px',
      borderStyle: 'solid',
      borderColor: palette.common.paleGrey,
    },
    hoveredItem: {
      background: palette.common.primary,
      color: palette.common.white,
      position: 'relative',
      '&:before': {
        content: '\'\'',
        position: 'absolute',
        top: '100%',
        width: 0,
        height: 0,
        borderTop: `10px solid ${palette.common.primary}`,
        borderRight: '10px solid transparent',
        borderLeft: '10px solid transparent',
        borderBottom: '10px solid transparent',
      },
    },
    tableHeader: {
      borderBottom: `.5px solid ${palette.common.paleGrey}`,
    },
    noBorder: {
      border: 'none',
    },
    noRightPadding: {
      paddingRight: 0,
    },
    curvedTopBorder: {
      borderTop: `.5px solid ${palette.common.paleGrey}`,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    curvedBottomBorder: {
      borderBottom: `.5px solid ${palette.common.paleGrey}`,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    button: {
      marginTop: spacing(1),
      '&:last-child': {
        marginBottom: spacing(2),
      },
    },
    card: {
      borderRadius: 10,
      border: `.5px solid ${palette.common.paleGrey}`,
      marginBottom: spacing(2),
      overflow: 'hidden',
    },
    cardCollapse: {
      width: '100%',
    },
    cardRow: {
      '&:nth-child(2)': {
        borderBottom: `.5px solid ${palette.common.paleGrey}`,
      },
    },
    cardRowExpanded: {
      '&:last-child': {
        marginBottom: spacing(2),
      },
      '&:nth-child(2n + 1)': {
        background: palette.common.fadeGrey,
      },
      '& p': {
        fontSize: 12,
      },
    },
    cardCol: {
      padding: spacing(1, 2),
    },
    cardColKey: {
      textAlign: 'right',
      '& p': {
        fontWeight: 'bold',
      },
      '& a': {
        color: palette.common.darkGrey,
      },
    },
    expandIcon: {
      margin: spacing(2, 0),
    },
    rootButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      [breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    rootButton: {
      margin: spacing(1),
    },
    rowCardHeader: {
      padding: spacing(2),
      borderBottom: `.5px solid ${palette.common.paleGrey}`,
      '& p': {
        marginRight: 'auto',
        textAlign: 'left',
        marginLeft: spacing(2),
      },
    },
    rowCardCollapse: {
      padding: spacing(3),
    },
    rowCardContent: {
      '&:not(:last-child)': {
        marginBottom: spacing(2),
      },
      textAlign: 'left',
    },
    rowCardContentKey: {
      fontWeight: 'bold',
      display: 'block',
      marginBottom: spacing(1),
      '& p': {
        display: 'inline',
        color: `${palette.common.darkGrey} !important`, // Override content inline text color
      },
    },
    rowCardContentValue: {
      '& *': {
        fontWeight: 'initial',
      },
    },
  }
}
