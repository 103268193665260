export default (theme) => {
  const { white } = theme.palette.common
  const { maxContentWidth } = theme.global

  return {
    mainContainer: {
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
      [theme.breakpoints.down('xs')]: {
        '& .quotation': {
          margin: 0,
        },
        '& .quotation::before': {
          content: 'none',
        },
        '& .quotation::after': {
          content: 'none',
        },
      },
    },

    header: {
      marginTop: '3rem',
      marginBottom: '3rem',
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },

    subheader: {
      [theme.breakpoints.down('md')]: {
        margin: '1rem 2rem',
      },
    },

    secondaryContainer: {
      maxWidth: maxContentWidth,
      textAlign: 'center',
      margin: '5rem auto 5rem auto',
    },

    cardsBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '4rem auto',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
        marginBottom: 0,
      },
      '& .slide': {
        transform: 'scale(0.8)',
        transition: '300ms transform ease',
      },
      '& .slide.selected': {
        transform: 'scale(1)',
      },
      '& .control-dots': {
        position: 'relative',
        padding: 0,
        marginTop: '2rem',
      },
    },

    card: {
      width: '32%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: '0 auto',
      },
    },

    box: {
      width: '100%',
      height: '100%',
      backgroundColor: white,
      borderRadius: '10px',
      padding: '2rem 1rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        borderRadius: '3px',
      },
    },

    content: {
      [theme.breakpoints.down('xs')]: {
        margin: '2rem auto',
      },
    },
  }
}
