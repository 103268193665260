export default (theme) => {
  const { darkGrey, white } = theme.palette.common

  return {
    columnContainer: {
      display: 'flex',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      color: darkGrey,
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        flexDirection: 'column',
        alignItems: 'center',
      },
    },

    columnContainerDark: {
      color: white,
    },

    columnContainerFlipped: {
      flexDirection: 'row-reverse',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
    },

    mediaColumn: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        margin: '0rem 1.5rem',
      },
    },

    mediaHtml: {
      width: '100%',
    },

    textColumn: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        maxWidth: 600,
        textAlign: 'center',
        flexDirection: 'unset',
        marginTop: theme.spacing(4),
        margin: '0rem 1.5rem',
      },
    },

    textColumnRight: {
      alignItems: 'flex-end',
    },

    textContent: {
      width: '80%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        textAlign: 'center',
        marginBottom: theme.spacing(4),
      },
    },

    button: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },

    title: {
      marginBottom: theme.spacing(2),
      '& *': {
        padding: 0,
        margin: 0,
      },
    },

    mediaImageSize: {
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 578,
      },
      [theme.breakpoints.down('sm')]: {
        width: 552,
      },
      [theme.breakpoints.only('xs')]: {
        width: 330,
      },
    },

    skeletonContainer: {
      backgroundColor: 'white',
      padding: '2rem',
      [theme.breakpoints.down('sm')]: {
        margin: '0 1rem',
      },
    },

    skeleton: {
      textAlign: '-webkit-center',
    },
  }
}
