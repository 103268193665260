export default (theme) => {
  const { isLightColor } = theme.utils
  const {
    primary,
    white,
  } = theme.palette.common

  return {
    mainContainer: {
      textAlign: 'center',
    },

    h4: ({ backgroundColor }) => ({
      marginTop: '3rem',
      marginBottom: '3rem',
      color: isLightColor(backgroundColor) ? primary : white,
    }),

    posts: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },

    post: {
      width: '30%',
      margin: '1rem auto',
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },

    link: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },

    box: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.white,
      paddingBottom: theme.spacing(2),
      borderRadius: 10,
    },

    imageContainer: ({ coverImage }) => ({
      width: '100%',
      height: '10rem',
      overflow: 'hidden',
      backgroundImage: `url('${coverImage}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '10px 10px 0px 0px',
    }),

    postContent: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    postInfo: {
      marginTop: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 2),
    },

    time: {
      marginBottom: theme.spacing(1),
    },

    button: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
    },

    postTagsContainer: {
      marginTop: theme.spacing(2),
    },

    tag: {
      margin: theme.spacing(0.25),
    },
  }
}
