export default (theme) => {
  const { maxContentWidth } = theme.global
  const { primary, white, darkGrey } = theme.palette.common
  const { isLightColor } = theme.utils

  return {
    blocksContainer: ({ backgroundColor }) => ({
      textAlign: 'center',
      maxWidth: maxContentWidth,
      margin: '5rem auto',
      justifyContent: 'center',
      color: isLightColor(backgroundColor) ? darkGrey : white,
      [theme.breakpoints.down('md')]: {
        margin: '3rem auto',
      },
    }),

    title: {
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        margin: '0rem 1.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2rem',
      },
    },

    subtitle: {
      marginTop: '1rem',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        marginLeft: '1.5rem',
        marginRight: '1.5rem',
      },
    },

    carouselWrapper: {
      marginTop: '1rem',
      display: 'flex',
      flexFlow: 'wrap',
    },

    carouselImages: {
      order: 2,
      flex: 1,
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    arrowButton: {
      borderRadius: 'unset',
      color: '#666666',
      padding: 0,
    },

    arrowButtonPrev: ({ isMobile }) => {
      const style = {
        order: 1,
        paddingRight: '20px',
        ...isMobile ? {
          paddingRight: '0px',
          minWidth: '24px',
          width: '24px',
        } : null,
      }

      return (style)
    },

    arrowButtonNext: ({ isMobile }) => {
      const style = {
        order: 3,
        paddingLeft: '20px',
        ...isMobile ? {
          paddingLeft: '0px',
          minWidth: '24px',
          width: '24px',
        } : null,
      }

      return (style)
    },

    indicators: {
      display: 'flex',
      justifyContent: 'center',
      padding: '14px',
      gap: '5px',
    },

    customIndicators: {
      order: 4,
      flexBasis: '100%',
    },

    indicator: {
      color: '#F8AAAA',
    },

    selectedIndicator: {
      color: primary,
    },

    hide: {
      opacity: 0,
      cursor: 'unset',
    },

    button: {
      marginTop: '1rem',
    },

    carouselImg: {
      width: '100%',
      height: 'auto',
    },
  }
}
