export default (theme) => {
  const mobileFloatingBarItemHeight = 115

  return {
    tabContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    blockContainer: {
      padding: '0 1rem',
      overflowX: 'hidden',
    },
    imagesContainer: {
      justifyContent: 'center',
      gridColumnGap: '3.2rem',
    },
    button: {
      marginTop: '2.5rem',
    },
    contentContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        padding: '0 3rem',
        overflowWrap: 'anywhere',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '1rem 0',
      },
    },

    floatingBarContainer: ({ itemsLength }) => ({
      border: `2px solid ${theme.palette.common.paleGrey}`,
      [theme.breakpoints.down('sm')]: {
        border: `1px solid ${theme.palette.common.paleGrey}`,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: `${itemsLength * mobileFloatingBarItemHeight}px`,
        width: 'inherit',
        padding: 0,
        borderRadius: '14px',
        overflow: 'visible',
        marginRight: '3rem',
      },
    }),

    floatingBarItem: {
      [theme.breakpoints.down('sm')]: {
        minHeight: `${mobileFloatingBarItemHeight}px`,
        maxHeight: `${mobileFloatingBarItemHeight}px`,
        display: 'flex',
        flexDirection: 'column',
        borderRight: 'none',
        borderBottom: `1px ${theme.palette.common.paleGrey} solid`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },

    floatingBarItemActive: {
      borderWidth: 'thick',
      '&:before': {
        content: '""',
        position: 'absolute',
        height: 'calc(100% + 8px)',
        width: 'calc(100% + 8px)',
        border: `8px solid ${theme.palette.common.primary}`,
        borderRadius: '14px',
      },
      '&:after': {
        bottom: '-1.2rem',
      },
      [theme.breakpoints.down('sm')]: {
        '&:after': {
          content: '""',
          position: 'absolute',
          height: 0,
          width: 0,
          top: '37%',
          left: '100%',
          border: '15px solid transparent',
          borderLeft: `15px solid ${theme.palette.common.primary}`,
        },
      },
    },

    carouselItem: {
      [theme.breakpoints.up('md')]: {
        marginTop: '4rem',
      },
    },

    tabImageContainer: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        overflow: 'inherit',
      },
    },

    tabImage: {
      maxWidth: '162px',
      [theme.breakpoints.up('sm')]: {
        width: 162,
        height: 162,
      },
      [theme.breakpoints.down('sm')]: {
        overflow: 'inherit',
        width: 112,
        height: 112,
        maxWidth: 112,
      },
    },

    carouselContent: {
      marginTop: '2rem',
    },

    tabsBlockTitle: {
      marginBottom: '3rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '2rem',
      },
    },

    blockForegroundContainer: {
      padding: '0 5rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0 1rem',
      },
    },

    lastImages: {
      marginBottom: 0,
    },
  }
}
