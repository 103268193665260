export default (theme) => {
  const { darkGrey, white } = theme.palette.common
  const { isLightColor } = theme.utils

  return {
    columnContainer: ({ backgroundColor, flipped }) => ({
      display: 'flex',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      color: isLightColor(backgroundColor) ? darkGrey : white,
      flexDirection: flipped ? 'row-reverse' : 'row',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        flexDirection: 'column',
        alignItems: 'center',
      },
    }),

    imageColumn: {
      flex: 1,
      margin: theme.spacing(2),
    },

    imageContainer: ({ flipped }) => {
      return {
        marginLeft: flipped ? '10%' : 0,
        [theme.breakpoints.down('md')]: {
          marginLeft: 0,
        },
      }
    },

    textColumn: ({ flipped }) => ({
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: flipped ? 'flex-start' : 'flex-end',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        flexDirection: 'unset',
      },
      margin: '0 auto',
    }),

    textContent: ({ alignment }) => ({
      width: '80%',
      textAlign: alignment ? alignment[0] : 'left',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    }),

    button: {
      marginTop: theme.spacing(4),
    },

    h5: {
      marginBottom: theme.spacing(2),
    },
  }
}
