export default (theme) => {
  const {
    white,
    lightPink,
  } = theme.palette.common

  return {
    mainContainer: {
      backgroundColor: white,
      textAlign: 'center',
      marginTop: theme.spacing(14),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      },
    },

    secondaryContainer: {
      position: 'relative',
    },

    line: {
      width: '66%',
      position: 'absolute',
      borderTop: `3px ${lightPink} solid`,
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: -1,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    textSpacing: {
      margin: theme.spacing(4, 0),
    },

    steps: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      margin: theme.spacing(8, 0),
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        margin: theme.spacing(0, 8),
      },
    },

    step: {
      flex: 1,
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
        marginBottom: 0,
      },
    },

    number: {
      backgroundColor: lightPink,
      padding: theme.spacing(0.3, 2.3),
      borderRadius: '50%',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.4, 3),
      },
    },
  }
}
