import { Box, Typography, Underline } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'

import Block from '~/src/components/Block'
import Icon from '~/src/components/Icon'
import ImageLink from '~/src/components/ImageLink'
import LinkButton from '~/src/components/LinkButton'
import { useIsMobile } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const HeroLeft = ({ image }) => {
  const classes = useStyles()
  return (
    <div className={classes.heroLeft}>
      <ImageLink {...image} imgStyles={classes.heroImage} />
    </div>
  )
}

HeroLeft.propTypes = {
  image: PropTypes.array.isRequired,
}

const CustomButton = ({ target, url, text, color, identity, event_name }) => {
  const classes = useStyles()

  return (
    <LinkButton
      color={color}
      target={target}
      to={url}
      className={classes.button}
      data-identity={identity}
      event_name={event_name}
    >
      {text}
    </LinkButton>
  )
}

CustomButton.propTypes = {
  target: PropTypes.array,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  identity: PropTypes.string,
  event_name: PropTypes.string,
}

CustomButton.defaultProps = {
  target: ['default'],
  identity: '',
  event_name: '',
}

const HeroRight = ({
  subtitle1,
  title,
  subtitle2,
  description,
  buttons,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <div className={classes.heroRight}>
      <Typography variant="subtitle1" component="h1">
        {subtitle1}
      </Typography>
      <div className={classes.title}>
        <Typography
          variant="h3"
          component="h2"
        >
          {title}
        </Typography>
        <Underline
          align={isMobile ? 'center' : 'left'}
          color="white"
          width={isMobile ? '4rem' : '1.5rem'}
          className={classes.titleUnderline}
        />
      </div>
      <Typography variant="subtitle1" component="h2">
        {subtitle2}
      </Typography>
      <Typography
        html={description}
        component="div"
        className={classes.description}
      />
      {!!buttons && (
        <div className={classes.buttons}>
          {buttons.map((button, index) => (
            <CustomButton
              {...button}
              key={button.text}
              color={index % 2 ? 'primary' : 'white'}
            />
          ))}
        </div>
      )}
    </div>
  )
}

HeroRight.propTypes = {
  subtitle1: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle2: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttons: PropTypes.array,
}

HeroRight.defaultProps = {
  buttons: [],
}

const Hero = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.hero}>
      <HeroLeft {...props} />
      <HeroRight {...props} />
    </div>
  )
}

const CarouselBlock = ({
  carousel_items,
}) => {
  const classes = useStyles()
  const ref = useRef()
  const isMobile = useIsMobile()
  const defaultIndex = carousel_items.findIndex((item) => !!item.isDefault)
  const [selectedIndex, setSelectedIndex] =
    useState(defaultIndex > -1 ? defaultIndex : 0)

  const FloatingBar = () => {
    return (
      <Box
        ref={ref}
        className={classes.floatingBar}
        boxShadow={5}
      >
        {carousel_items.map((element, index) => (
          <FloatingItem
            item={element}
            index={index}
            key={element.link.text}
          />
        ))}
      </Box>
    )
  }

  const FloatingItem = ({ item, index }) => {
    return (
      <div
        className={cx({
          [classes.thumbnailItem]: true,
          [classes.activeThumbnail]: selectedIndex === index,
        })}
        key={item.link.text}
        onClick={() => setSelectedIndex(index)}
        data-identity={item.link.identity}
      >
        <Icon
          className={classes.thumbnailIcon}
          {...item.icon}
        />
        <Typography className={classes.thumbnailLabel}>
          {item.link.text}
        </Typography>
        {selectedIndex === index && <Underline className={classes.underline} />}
      </div>
    )
  }

  FloatingItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  }

  return (
    <Block
      color={carousel_items[selectedIndex].color}
      fullWidth
      containerClassName={classes.mainContainer}
      foregroundClassName={classes.foregroundContainer}
    >
      <ReactCarousel
        selectedItem={selectedIndex}
        showThumbs={false}
        showIndicators={false}
        showArrows={false}
        showStatus={false}
        onChange={setSelectedIndex}
        swipeable={!isMobile}
      >
        {carousel_items.map((hero) => (
          <Hero key={hero.title} {...hero} />
        ))}
      </ReactCarousel>
      {carousel_items.length > 1 && <FloatingBar />}
    </Block>
  )
}

CarouselBlock.propTypes = {
  carousel_items: PropTypes.array.isRequired,
}

export default CarouselBlock
