export default (theme) => {
  const { maxContentWidth } = theme.global

  return {
    formContainer: {
      maxWidth: maxContentWidth / 2,
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        margin: '0 1.5rem',
      },
    },
  }
}
