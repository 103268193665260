import { makeStyles } from '@pickupp/ui/styles'
import React from 'react'

import LinkButton from '~/src/components/LinkButton'

import styles from '../styles'

const useStyles = makeStyles(styles)

const Button = ({ button }) => {
  const classes = useStyles()

  if (!button) return null

  return (
    <LinkButton
      to={button.url}
      target={button.target}
      className={classes.button}
      event_name={button.event_name}
      data-identity={button.identity}
      color="primary"
    >
      {button.text}
    </LinkButton>
  )
}

export default Button
