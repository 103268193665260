import {
  Cell,
  Grid,
  isWidthUp,
  Typography,
  useWidth,
} from '@pickupp/ui/core'
import { makeStyles, useTheme } from '@pickupp/ui/styles'
import cx from 'classnames'
import React, { useState } from 'react'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'

import Block from '~/src/components/Block'
import FloatingBar from '~/src/components/FloatingBar'
import Image from '~/src/components/Image'
import LinkButton from '~/src/components/LinkButton'

import styles from './styles'

const useStyles = makeStyles(styles)

const Content = ({ item }) => {
  const classes = useStyles()

  return (
    <Cell
      xs={12}
      md={6}
      key={item?.link.text}
    >
      <Grid className={classes.contentContainer}>
        <Typography
          html={item.content}
          component="div"
        />
        {item.button && (
          <LinkButton
            to={item.button.url}
            target={item.button.target}
            data-identity={item.button.identity}
            className={classes.button}
            event_name={item.button.event_name}
          >
            {item.button.text}
          </LinkButton>
        )}
      </Grid>
    </Cell>
  )
}

const Images = ({ item }) => {
  const classes = useStyles()

  if (!item?.images) return null

  const odd = item?.images?.length % 2
  const lastImages = odd ? item?.images?.length - 1 : item?.images?.length - 2

  return (
    <Cell xs={6}>
      <Grid className={classes.imagesContainer}>
        {item.images.map((image, imageIndex) => (
          <Image
            key={image.src}
            src={image.src}
            alt={image.alt}
            className={cx([classes.tabImageContainer], {
              [classes.lastImages]: imageIndex >= lastImages,
            })}
            imgClassName={classes.tabImage}
          />
        ))}
      </Grid>
    </Cell>
  )
}

const TabsBlock = ({ title, tabs }) => {
  const width = useWidth(useTheme())
  const notMobile = isWidthUp('md', width)
  const classes = useStyles({ itemsLength: tabs.length })
  const items = tabs.map((tab) => ({
    ...tab,
    link: tab.link,
    icon: tab.icon,
  }))

  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Block
      foregroundClassName={classes.blockForegroundContainer}
    >
      <Grid>
        <Cell xs={12} className={classes.tabsBlockTitle}>
          <Typography variant="h2" style={{ textAlign: 'center' }}>{title}</Typography>
        </Cell>
        {items.length > 1 && (
          <Cell xs={6} md={12}>
            <FloatingBar
              items={items}
              float={false}
              boxShadow={!notMobile ? 3 : 0}
              containerClassName={classes.floatingBarContainer}
              itemActiveClassName={classes.floatingBarItemActive}
              itemClassName={classes.floatingBarItem}
              filled
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </Cell>
        )}

        {!notMobile && (
          <Cell
            xs={6}
            key={items[selectedIndex]}
            className={classes.tabContainer}
          >
            <Images item={items[selectedIndex]} />
          </Cell>
        )}

        <Cell xs={12} className={classes.carouselContent}>
          <ReactCarousel
            showThumbs={false}
            showIndicators={false}
            showArrows={false}
            showStatus={false}
            selectedItem={selectedIndex}
            onChange={setSelectedIndex}
            swipeable={notMobile}
          >
            {items.map((item) => (
              <Grid key={item?.link?.text} className={classes.carouselItem}>
                <Content item={item} />
                {notMobile && (
                  <Images item={item} />
                )}
              </Grid>
            ))}
          </ReactCarousel>
        </Cell>
      </Grid>
    </Block>
  )
}

export default TabsBlock
