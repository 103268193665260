import { Cell, Grid, Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Animation from '~/src/components/Animation'
import Block from '~/src/components/Block'
import LinkButton from '~/src/components/LinkButton'
import { BlockContext } from '~/src/contexts'

import styles from './styles'

const useStyles = makeStyles(styles)

const DeliverBlock = ({
  title,
  boxes,
  subtitle,
  description,
  link,
}) => {
  const { backgroundColor } = useContext(BlockContext)
  const classes = useStyles({ backgroundColor })

  return (
    <Block
      fullWidth
      foregroundClassName={classes.blocksContainer}
    >
      <Animation
        disabled
        animation="slideUp"
      >
        <Typography
          variant="h4"
          component="h1"
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography
          className={classes.subtitle}
          html={subtitle}
        />
      </Animation>
      <Grid className={classes.blocks}>
        {boxes.map((block, index) => (
          <Cell key={index} xs={6} s={6} md={3} className={classes.itemContainer}>
            <Animation
              key={index}
              animation="number"
              delay={200 * index}
              className={classes.block}
            >
              <Typography
                html={block.content}
              />
            </Animation>
          </Cell>
        ))}
      </Grid>
      {description && (
        <Animation
          disabled
          animation="slideUp"
        >
          <Typography
            className={classes.description}
            html={description}
          />
        </Animation>
      )}
      {link && (
        <LinkButton
          to={link.url}
          target={link.target}
          data-identity={link.identity}
          event_name={link.event_name}
        >
          {link.text}
        </LinkButton>
      )}
    </Block>
  )
}

DeliverBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  boxes: PropTypes.array.isRequired,
  description: PropTypes.string,
  link: PropTypes.object,
}

DeliverBlock.defaultProps = {
  subtitle: '',
  description: '',
  link: null,
}

export default DeliverBlock
