import stroke from '~/src/assets/stroke.svg'

export default () => {
  return {
    stroke: {
      top: 0,
      left: 0,
      position: 'absolute',
      width: '100vw',
      height: '10000px',
      background: `transparent url(${stroke}) no-repeat center top`,
      zIndex: 1,
    },
  }
}
