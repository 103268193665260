import {
  Cell,
  Grid,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'
import Image from '~/src/components/Image'

import styles from './styles'

const useStyles = makeStyles(styles)

const ThreeImagesBlock = ({ title, image_1, image_2, image_3 }) => {
  const classes = useStyles()
  const images = (image_1?.src && image_2?.src && image_3?.src) ?
    [image_1, image_2, image_3] :
    []
  return (
    <Block>
      {title && (
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      )}
      <Grid>
        {images.map(({ src, alt, label, description }) => {
          return (
            <Cell md={4} key={src} className={classes.imageContainer}>
              <Image
                src={src}
                alt={alt}
                imgClassName={classes.image}
              />
              <div className={classes.imageTextContainer}>
                {label && (
                  <Typography className={classes.label}>
                    {label}
                  </Typography>
                )}
                {description && (
                  <Typography
                    html={description}
                    component="div"
                    className={classes.description}
                  />
                )}
              </div>
            </Cell>
          )
        })}
      </Grid>
    </Block>
  )
}

ThreeImagesBlock.propTypes = {
  title: PropTypes.string,
  image_1: PropTypes.object,
  image_2: PropTypes.object,
  image_3: PropTypes.object,
}

ThreeImagesBlock.defaultProps = {
  title: null,
  image_1: null,
  image_2: null,
  image_3: null,
}

export default ThreeImagesBlock
