import {
  Cell,
  Collapse,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'

import Block from '~/src/components/Block'
import Image from '~/src/components/Image'
import LinkButton from '~/src/components/LinkButton'
import { useBrowserSize } from '~/src/hooks'

import styles from './styles'

const useStyles = makeStyles(styles)

const CardContent = ({ content, isExpandedItem }) => {
  const classes = useStyles()
  return (
    <Grid
      direction="row"
      className={cx({
        [classes.cardRow]: !isExpandedItem,
        [classes.cardRowExpanded]: isExpandedItem,
      })}
    >
      {content.key && (
        <Cell
          xs
          className={cx(classes.cardCol, classes.cardColKey)}
          style={{ background: content.background_color }}
        >
          <Typography component="div" html={content.key} />
        </Cell>
      )}
      {content.value && (
        <Cell
          xs
          className={classes.cardCol}
          style={{ background: content.background_color }}
        >
          <Typography component="div" html={content.value} />
        </Cell>
      )}
      {content.links && (
        <Grid direction="column">
          {content.links.map((link, linkIdx) => (
            <LinkButton
              key={link.text}
              to={link.url}
              color={linkIdx % 2 === 0 ? 'primary' : 'white'}
              data-identity={link.identity}
              className={classes.button}
              event_name={link.event_name}
              target={link.target}
            >
              {link.text}
            </LinkButton>
          ))}
        </Grid>
      )}
    </Grid>
  )
}

const ColumnMobileCard = ({ content }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const collapsedContent = []
  const staticContent = []
  const lastContent = content[content.length - 1]

  content.forEach((row, rowIndex) => {
    if ([0, 1, 2].includes(rowIndex)) {
      staticContent.push(row)
    } else if (rowIndex !== content.length - 1) {
      collapsedContent.push(row)
    }
  })

  return (
    <Grid
      className={classes.card}
      justify="center"
    >
      {staticContent.map((c, contentIdx) => (
        <CardContent content={c} key={`row-${contentIdx}`} />
      ))}
      <Collapse
        in={expanded}
        className={classes.cardCollapse}
      >
        {collapsedContent.map((c, contentIdx) => (
          <CardContent
            isExpandedItem
            content={c}
            key={`collapsedRow-${contentIdx}`}
          />
        ))}
      </Collapse>
      <CardContent content={lastContent} />
      <div className={classes.expandIcon}>
        <Icon
          name={expanded ? 'expand_less' : 'expand_more'}
          size={32}
          onClick={() => setExpanded((prev) => !prev)}
        />
      </div>
    </Grid>
  )
}

const RowMobileCard = (({ content }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const [staticContent, ...collapsedContent] = content

  return (
    <Grid className={classes.card}>
      <Grid
        justify="space-between"
        alignItems="center"
        className={classes.rowCardHeader}
      >
        <div className={classes.colImageWrapper}>
          {staticContent.image && (
            <Image
              src={staticContent.image.src}
              alt={staticContent.image.alt}
              className={classes.colImage}
            />
          )}
        </div>
        <Typography component="div" html={staticContent.value} />
        <div className={classes.expandIcon}>
          <Icon
            name={expanded ? 'expand_less' : 'expand_more'}
            size={32}
            onClick={() => setExpanded((prev) => !prev)}
          />
        </div>
      </Grid>
      <Collapse
        in={expanded}
        className={classes.cardCollapse}
      >
        <div className={classes.rowCardCollapse}>
          {collapsedContent.map((c, contentIdx) => (
            <div key={`content-${contentIdx}`} className={classes.rowCardContent}>
              <Typography component="div" html={c.key} className={classes.rowCardContentKey} />
              <Typography component="div" html={c.value} className={classes.rowCardContentValue} />
            </div>
          ))}
        </div>
      </Collapse>
    </Grid>
  )
})

const MobileTable = ({ cards, mobileLayout }) => {
  return cards.map((cardRows, index) => (mobileLayout === 'base_column' ?
    <ColumnMobileCard content={cardRows} key={`card-${index}`} /> :
    <RowMobileCard content={cardRows} key={`card-${index}`} />
  ))
}

const DesktopTable = ({
  rows,
  handleMouseEnter,
  hoveredIndex,
  isRowLayout,
}) => {
  const classes = useStyles()

  return rows.map((row, index) => (
    <TableRow
      key={`row-${index}`}
      className={cx({
        [classes.tableRow]: !isRowLayout,
        [classes.extendedTableRow]: isRowLayout && ![0, rows.length - 1].includes(index),
      })}
    >
      {row.columns.map((column, idx) => (
        <TableCell
          key={`column-${idx}`}
          className={cx(classes.tableCol, {
            [classes.noRightPadding]: idx === row.columns.length - 1,
            [classes.defaultTableCol]: !isRowLayout,
            [classes.extendedTableCol]: isRowLayout,
          })}
        >
          <div
            className={cx(classes.tableColContent, {
              [classes.hoveredItem]: hoveredIndex === idx && index === 1,
              [classes.noBorder]: idx === 0,
              [classes.curvedTopBorder]: idx !== 0 && index === 0,
              [classes.curvedBottomBorder]: idx !== 0 && index === rows.length - 1,
              [classes.tableHeader]: idx !== 0 && index === 1 && !isRowLayout,
            })}
            style={{ background: column.background_color }}
            onMouseEnter={() => handleMouseEnter(idx)}
          >
            <>
              {idx === 0 && isRowLayout && (
                <div className={classes.colImageWrapper}>
                  {column.image && (
                    <Image
                      key={`${idx}_${column.image.alt}`}
                      src={column.image.src}
                      alt={column.image.alt}
                      className={classes.colImage}
                    />
                  )}
                </div>
              )}
              {column.content && (
                <Typography
                  component="div"
                  html={column.content}
                />
              )}
            </>
            {column.links?.length > 0 && (
              <Grid direction="column">
                {column.links.map((link, linkIdx) => (
                  <LinkButton
                    key={link.text}
                    to={link.url}
                    color={linkIdx % 2 === 0 ? 'primary' : 'white'}
                    data-identity={link.identity}
                    className={classes.button}
                    event_name={link.event_name}
                    target={link.target}
                  >
                    {link.text}
                  </LinkButton>
                ))}
              </Grid>
            )}
          </div>
        </TableCell>
      ))}
    </TableRow>
  ))
}
const FormattedTableBlock = ({
  title,
  rows,
  buttons,
  hoverEffect,
  mobile_layout,
}) => {
  const classes = useStyles()
  const size = useBrowserSize()
  const [hoveredIndex, setHoveredIndex] = useState(-1)
  const isRowLayout = mobile_layout === 'base_row'

  const handleMouseEnter = (index) => {
    if (hoverEffect) {
      if (index === hoveredIndex) return
      setHoveredIndex(index === 0 ? -1 : index)
    }
  }

  const handleMouseLeave = () => {
    if (hoverEffect) {
      setHoveredIndex(-1)
    }
  }

  const cardsData = useMemo(() => {
    const result = []
    if (mobile_layout === 'base_column') {
      for (let i = 1; i < rows[0].columns.length; i++) {
        const data = rows.map((row, index) => {
          const key = (index !== 2) ? row.columns[0].content : null
          const value = row.columns[i].content
          const { links, background_color } = row.columns[i]
          return {
            key: key || null,
            value: value || null,
            links,
            backgroundColor: background_color,
          }
        })
        result.push(data)
      }
    } else {
      for (let i = 1; i < rows.length; i++) {
        const data = rows[i].columns.map((column, index) => {
          const key = (index > 0) ? rows[0].columns[index].content : null
          const { image } = column
          const value = column.content
          return {
            key: key || null,
            image,
            value,
          }
        })
        const [first, second, third, ...rest] = data
        const secondValue = second.value.split('\n')
        const thirdValue = third.value.split('\n')
        let mergedValue = ''
        secondValue.forEach((val, index) => {
          mergedValue += `${val}, ${thirdValue[index]}\n`
        })
        result.push([
          first,
          {
            key: `${second.key}, ${third.key}`,
            value: mergedValue,
          },
          ...rest,
        ])
      }
    }
    return result
  }, [rows])

  return (
    <Block containerClassName={classes.mainContainer} fullWidth>
      <Typography
        component="div"
        html={title}
      />
      {['xs', 'sm', 'md'].includes(size) ? (
        <MobileTable
          cards={cardsData}
          mobileLayout={mobile_layout}
        />
      ) : (
        <Table
          className={classes.table}
          onMouseLeave={handleMouseLeave}
        >
          <TableBody>
            <DesktopTable
              rows={rows}
              handleMouseEnter={handleMouseEnter}
              hoveredIndex={hoveredIndex}
              isRowLayout={isRowLayout}
            />
          </TableBody>
        </Table>
      )}
      <div className={classes.rootButtonWrapper}>
        {buttons?.map((link, index) => (
          <LinkButton
            key={link.text}
            to={link.url}
            color={index % 2 ? 'white' : 'primary'}
            data-identity={link.identity}
            className={classes.rootButton}
            event_name={link.event_name}
            target={link.target}
          >
            {link.text}
          </LinkButton>
        ))}
      </div>
    </Block>
  )
}

FormattedTableBlock.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array.isRequired,
  buttons: PropTypes.array,
  mobile_layout: PropTypes.string,
}

FormattedTableBlock.defaultProps = {
  title: '',
  buttons: [],
  mobile_layout: 'base_column',
}

export default FormattedTableBlock
