export default (theme) => {
  const {
    secondary,
    white,
  } = theme.palette.common

  return {
    mainContainer: {
      display: 'flex',
      textAlign: 'center',
    },

    fullHeight: {
      height: '100%',
    },

    cards: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6),
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    box: {
      width: '100%',
      height: '100%',
      borderRadius: '10px',
      backgroundColor: white,
      padding: '28px 21px',
      [theme.breakpoints.down('md')]: {
        padding: '2rem',
      },
    },

    boxHeader: {
      boxSizing: 'initial',
      minHeight: '56px',
      height: 'auto',
      marginBottom: '20px',
      paddingBottom: '21px',
      borderBottom: '2px solid #E5E5E5',
    },

    boxContent: {
      width: '100%',
    },

    boxIcon: {
      maxWidth: '56px',
      maxHeight: '56px',
      fontSize: '5rem !important',
      marginRight: '12px',
    },

    boxTitle: {
      fontSize: '1.5rem',
      textAlign: 'left',
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },

    icon: {
      color: secondary,
      display: 'inline-flex',
      borderRadius: '100%',
      objectFit: 'fill',
    },

    row: {
      textAlign: 'left',
    },

    button: {
      marginTop: theme.spacing(2),
    },

    subheader: {
      margin: theme.spacing(4, 1, 0, 1),
    },
  }
}
