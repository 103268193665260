import { Typography } from '@pickupp/ui/core'
import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'

import styles from './styles'

const useStyles = makeStyles(styles)

const Steps = ({ steps }) => {
  const classes = useStyles()

  return (
    <div className={classes.secondaryContainer}>
      <div className={classes.line} />
      <div className={classes.steps}>
        {steps.map((step, key) => (
          <div
            key={key}
            className={classes.step}
          >
            <Typography
              variant="h4"
              component="span"
              className={classes.number}
              color="primary"
            >
              {key + 1}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              className={classes.textSpacing}
            >
              {step.label}
            </Typography>
            <Typography
              html={step.description}
              component="div"
              className={classes.textSpacing}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
}

const ThreeStepsBlock = ({
  title,
  subtitle,
  steps,
}) => {
  const classes = useStyles()

  return (
    <Block
      foregroundClassName={classes.mainContainer}
    >
      <Typography
        variant="h4"
        component="h1"
        className={classes.textSpacing}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        className={classes.textSpacing}
      >
        {subtitle}
      </Typography>
      <Steps steps={steps} />
    </Block>
  )
}

ThreeStepsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
}

export default ThreeStepsBlock
