import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'
import Image from '~/src/components/Image'

import styles from './styles'

const useStyles = makeStyles(styles)

const ImageBlock = ({
  image,
  responsive_image,
}) => {
  const classes = useStyles()
  return (
    <Block>
      <div className={classes.mainContainer}>
        <Image
          src={image?.src}
          alt={image?.alt}
          link={image?.link}
          className={classes.image}
          responsiveImage={responsive_image}
        />
      </div>
    </Block>
  )
}

ImageBlock.propTypes = {
  image: PropTypes.object.isRequired,
  responsive_image: PropTypes.object,
}

ImageBlock.defaultProps = {
  responsive_image: null,
}

export default ImageBlock
