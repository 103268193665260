import { makeStyles } from '@pickupp/ui/styles'
import PropTypes from 'prop-types'
import React from 'react'

import Block from '~/src/components/Block'

import styles from './styles'

const useStyles = makeStyles(styles)

const SpaceBlock = ({
  spacing_height,
}) => {
  const classes = useStyles({ spacingHeight: spacing_height })

  return (
    <Block foregroundClassName={classes.container} />
  )
}

SpaceBlock.propTypes = {
  spacing_height: PropTypes.number.isRequired,
}

export default SpaceBlock
