export default (theme) => {
  const {
    black,
    paleGrey,
    primary,
  } = theme.palette.common
  const { maxContentWidth } = theme.global

  return {
    foregroundContainer: {
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
    },

    mainContainer: {
      maxWidth: maxContentWidth,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      padding: '2rem 0',
    },

    header: {
      textAlign: 'center',
    },

    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },

    backgroundImage: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },

    backgroundOverlay: {
      backgroundColor: black,
      opacity: 0.35,
    },

    headerContent: {
      margin: '0 auto',
      padding: '6rem 0',
      position: 'relative',
    },

    body: {
      margin: '4rem auto',
    },

    title: {
      margin: '2rem 0',
    },

    question: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: `1px solid ${paleGrey}`,
      borderBottom: `1px solid ${paleGrey}`,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      cursor: 'pointer',
      '&:hover': {
        color: primary,
      },
    },

    primaryColor: {
      color: primary,
    },

    answer: {
      maxHeight: 0,
      overflow: 'hidden',
      transition: 'max-height .25s, margin .25s',
      margin: 0,
    },

    activeAnswer: {
      maxHeight: 'unset',
      margin: '2rem 0',
    },

    answerContent: {
      overflow: 'auto',
      '& table p': {
        margin: 0,
      },
    },
  }
}
